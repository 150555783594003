import { Fade, Stack } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'next-i18next';

import {
  RippleBadge,
  RippleButton,
  RippleIconButton,
  RippleMenu,
  RippleMenuButton,
  RippleMenuItem,
  RippleMenuList,
  RippleMore,
  RippleTooltip,
  RippleTypography,
} from '@/design';
import { featureControl } from '@/feature/toggle';
import { useTeamInfoForChannelsQuery, useTeamInfoForChannels_deprecated } from '@/modules/ServiceDesk/Channels/useTeamInfoForChannels';
import { Survey } from '@/services/serviceDesk/surveys';
import { useTime } from '@/utils/formatDateTime';

type useSurveyColumnsProps = {
  onEdit: (id: number) => void;
  onClone: (id: number) => void;
  onDelete: (id: number) => void;
  onResume: (id: number) => void;
  onSuspend: (id: number) => void;
  onPreview: (id: number) => void;
  onAssignChannels: (id: number) => void;
};

export const useSurveyColumns = ({
  onEdit,
  onSuspend,
  onClone,
  onDelete,
  onResume,
  onAssignChannels,
  onPreview,
}: useSurveyColumnsProps) => {
  const { t, i18n } = useTranslation();
  const teamInfoForChannels_deprecated = useTeamInfoForChannels_deprecated();
  const teamInfoForChannelsQuery = useTeamInfoForChannelsQuery();

  const isExpired = featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product')
    ? teamInfoForChannelsQuery.data?.isExpired
    : teamInfoForChannels_deprecated.isExpired;

  const memberStatus = featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product')
    ? teamInfoForChannelsQuery.data?.memberStatus
    : teamInfoForChannels_deprecated.memberStatus;

  const isDisabled = isExpired || memberStatus !== 'enabled';
  const columnHelper = createColumnHelper<Survey>();
  const { formatDateTime } = useTime(i18n.language);

  return [
    columnHelper.accessor('name', {
      size: 310,
      header: () => t('common:name'),
      cell: ({ getValue }) => (
        <RippleTooltip aria-label={getValue()} label={getValue()}>
          <RippleTypography noOfLines={1} variant="body02" pl="8px">
            {getValue()}
          </RippleTypography>
        </RippleTooltip>
      ),
      sortingFn: (a, b) => a.original.name.localeCompare(b.original.name),
    }),
    columnHelper.accessor('description', {
      size: 350,
      header: () => t('survey:description'),
      cell: ({ getValue }) => (
        <RippleTooltip aria-label={getValue() ?? ''} label={getValue()}>
          <RippleTypography noOfLines={2} variant="body02">
            {getValue()}
          </RippleTypography>
        </RippleTooltip>
      ),
      sortingFn: (a, b) => (a.original.description ?? '').localeCompare(b.original.description ?? ''),
    }),
    columnHelper.accessor('created_at', {
      size: 192,
      header: () => t('survey:date_created'),
      cell: ({ getValue }) => <RippleTypography variant="body02">{formatDateTime(getValue())}</RippleTypography>,
    }),
    columnHelper.display({
      id: 'actions',
      size: 286,
      cell: ({ row }) => {
        const isSuspended = row.original.status === 0;
        const id = row.original.id;
        return (
          <Stack direction="row" gap="12px" alignItems="center" justifyContent="flex-end" flex={1}>
            <Fade in={isSuspended}>
              <RippleBadge colorScheme="grey">{t('common:suspended')}</RippleBadge>
            </Fade>
            <RippleButton variant="secondary" size="xs" isDisabled={isSuspended || isDisabled} onClick={() => onPreview(id)}>
              {t('common:preview')}
            </RippleButton>
            <RippleMenu>
              <RippleMenuButton as={RippleIconButton} variant="ghost" size="sm" icon={<RippleMore />}></RippleMenuButton>
              <RippleMenuList>
                <RippleMenuItem isDisabled={isDisabled} onClick={() => onEdit(id)}>
                  {t('common:edit')}
                </RippleMenuItem>
                <RippleMenuItem isDisabled={isDisabled} onClick={() => onAssignChannels(id)}>
                  {t('survey:assign_channel')}
                </RippleMenuItem>
                <RippleMenuItem isDisabled={isDisabled} onClick={() => onClone(id)}>
                  {t('common:clone')}
                </RippleMenuItem>
                {isSuspended ? (
                  <RippleMenuItem isDisabled={isDisabled} onClick={() => onResume(id)}>
                    {t('common:resume')}
                  </RippleMenuItem>
                ) : (
                  <RippleMenuItem isDisabled={isDisabled} onClick={() => onSuspend(id)}>
                    {t('common:suspend')}
                  </RippleMenuItem>
                )}
                <RippleMenuItem isDisabled={isDisabled} onClick={() => onDelete(id)}>
                  {t('common:delete')}
                </RippleMenuItem>
              </RippleMenuList>
            </RippleMenu>
          </Stack>
        );
      },
    }),
  ];
};
