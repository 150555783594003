import { useCallback, useContext, useEffect, useRef } from 'react';

import { StyleProps } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { Draft } from 'immer';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'next-i18next';
import { z } from 'zod';

import type { RippleTabsProps } from '@/design';
import { featureControl } from '@/feature/toggle';
import type { TeamKind } from '@/services/common/types';
import { updateTeamGrantGranularControl, updateTeamGranularControl } from '@/services/team';
import type {
  GranularControlDetailSettingKey,
  GranularControlKey,
  UpdateGrantGranularControlPayload,
  UpdateGranularControlPayload,
} from '@/services/team/types';
import { getMultipleSetting, getSingleSetting, updateTeamSettings } from '@/services/teamSettings';
import type { CommonSettingState, EditableSettingType, SeatPermissionsKey, SettingKey, TeamSettings } from '@/services/teamSettings/types';
import { postMessageToRails } from '@/utils/postMessageToRails';

import { tabStateMapAtom, tourStateMapAtom } from './atoms';
import { fileTransferMode, modalOffsetInfoDefault, mutationKeyMap, queryMainKey, tourKeys } from './constants';
import { ModalContext } from './contexts';
import { useSettingControlContext, useTeamControlContext } from './hooks';
import type {
  AvailableTeamKind,
  FeatureCategory,
  FeatureItemContextValue,
  GranularControlAvailableTeamKind,
  GranularMutationPayload,
  LayoutType,
  SOSTeamDetail,
  SettingMutationPayload,
  TabKind,
  TeamDetail,
  TeamMetadata,
  TeamMetadataSet,
  TourKind,
} from './types';

const modalCommonStyle: StyleProps = { minWidth: '820px', marginY: '0' };

export function useModalOffsetStyles(): StyleProps {
  const { offsetRef } = useContext(ModalContext);

  if (offsetRef.current === null)
    return {
      ...modalCommonStyle,
      maxHeight: `${modalOffsetInfoDefault.height}px`,
      top: `${modalOffsetInfoDefault.top}px`,
    };

  return {
    ...modalCommonStyle,
    maxHeight: `${offsetRef.current.height}px`,
    top: `${offsetRef.current.top}px`,
    ...(offsetRef.current.isMobile
      ? {
          minWidth: '90vw',
        }
      : {}),
  };
}

/**
 * ref: https://andyyou.github.io/2015/04/07/get-an-element-s-position-by-js/
 */
export function getElementPosition(element: HTMLElement) {
  let x = 0;
  let y = 0;
  let currentElement: HTMLElement | null = element;
  while (currentElement) {
    x += currentElement.offsetLeft - currentElement.scrollLeft + currentElement.clientLeft;
    y += currentElement.offsetTop - currentElement.scrollLeft + currentElement.clientTop;
    currentElement = currentElement.offsetParent as HTMLElement | null;
  }

  return { x: x, y: y };
}

export function getParentNavbarHeight(): number {
  return parent.document.getElementById('navbar')?.getBoundingClientRect().height ?? 55;
}

export function getIframeElement(): HTMLElement | null {
  return parent.document.getElementById('iframeForReact');
}

export function getParentScrollHeight(): number {
  return parent.document.documentElement.scrollHeight - parent.document.documentElement.clientHeight;
}

export function showErrorMessageOnRailsPage(errorMessage: string) {
  postMessageToRails({ type: 'error', error: errorMessage });
}

/**
 * Used on team tab naming while showing multiple team tabs
 * Normally it won't be used, just for fallback
 */
export function getTeamKindName(kind: AvailableTeamKind): string {
  // ref: https://github.com/SplashtopInc/be_kung_fu/blob/master/training/introduce-products.md
  const kindNameTable: Record<AvailableTeamKind, string> = {
    sos: 'Splashtop SOS',
    srs: 'Splashtop Remote Support',
    // stp: 'Splashtop Personal',
    sba: 'Splashtop Business Access',
    msp: 'Splashtop Remote Support',
    splashtop: 'Splashtop',
    ste_custom: 'Splashtop Enterprise',
  };

  return kindNameTable[kind];
}

export function getPlanName(kind: TeamKind, teamMetadata: TeamMetadata): string | null {
  const { plan } = teamMetadata;
  // ref: https://github.com/SplashtopInc/be_kung_fu/blob/master/be_app/api/web-api/v1/users.md#product_name-%E4%BB%A5%E5%8F%8A-plan-%E5%B0%8D%E7%85%A7
  //      https://splashtop.atlassian.net/wiki/spaces/TPEQA/pages/59081211/Business+Model
  //      https://docs.google.com/spreadsheets/d/1XbZGdh3l3P0JtfeW22Z4lAHBTaXIv_2eCyFo69Egq30/edit?gid=49267046#gid=49267046
  //      be-app/app/helpers/users_helper.rb get_product_name
  //      be-app/app/models/feature.rb get_sos_feature_name
  //      be-app/app/services/users/products/sos_service.rb
  switch (kind) {
    case 'stp': {
      return 'Splashtop Personal';
    }
    case 'sba': {
      if (plan === 'solo') return 'Splashtop Business Access Solo';
      if (plan === 'pro') return 'Splashtop Business Access Pro';
      if (plan === 'performance') return 'Splashtop Business Access Performance';
      return 'Splashtop Business Access';
    }
    case 'msp': {
      return 'Splashtop Remote Support';
    }
    case 'srs': {
      if (plan === 'basic') return 'Splashtop Remote Support Basic';
      if (plan === 'plus' || plan === 'plus_from_sos_unlimited' || plan === 'plus_from_sos') return 'Splashtop Remote Support Plus';
      if (plan === 'premium' || plan === 'premium_from_sos') return 'Splashtop Remote Support Premium';
      if (plan === 'enterprise') return 'Splashtop Enterprise';
      if (plan === 'ste_lite_sos_plus') return 'Splashtop SOS Plus'; // Note: New SOS Plus, based on STE
      if (plan === 'ste_lite_sos_basic') return 'Splashtop SOS Basic'; // Note: New SOS Basic, based on STE

      if (
        featureControl.getToggle('PCP_2061__TeamSettings__SOS_Unlimited_team_computer_quota_hardcode_adjustment') &&
        plan === 'ste_lite_sos_unlimited'
      )
        return 'Splashtop SOS Unlimited'; // Note: New SOS Unlimited (Same functionality with New SOS Plus), based on STE
      return null;
    }
    case 'sos': {
      if (plan === 'legacy') return 'Splashtop SOS Legacy';
      if (plan === 'with_mobile' || plan === 'basic') return 'Splashtop SOS Basic';
      if (plan === 'plus') return 'Splashtop SOS Plus';
      if (plan === 'enterprise') return 'Splashtop SOS Enterprise';
      if (plan === 'teams') return 'Splashtop SOS Teams';
      if (plan === 'unlimited') return 'Splashtop SOS Unlimited';
      return null;
    }
    case 'splashtop': {
      return 'Splashtop';
    }
    case 'ste_custom': {
      return 'Splashtop Enterprise';
    }
    default: {
      return null;
    }
  }
}

/**
 * @deprecated Use `useFeatureState` in `@/modules/TeamSettings/hooks/useFeatureState.ts` instead
 */
export function useFeatureSetting<Key extends SettingKey = SettingKey>(settingKey: Key) {
  const { atoms } = useTeamControlContext();
  const featureSettingAtom = selectAtom(
    atoms.teamSettingsAtom,
    useCallback((settings: TeamSettings) => settings[settingKey], [settingKey]),
    isEqual,
  );

  return useAtomValue(featureSettingAtom) ?? null;
}

/**
 * @deprecated Use `useFeatureStateMap` in `@/modules/TeamSettings/hooks/useFeatureStateMap.ts` instead
 */
export function useFeatureSettings() {
  const { atoms } = useTeamControlContext();

  return useAtomValue(atoms.teamSettingsAtom);
}

export function useUpdateSettings(): (draftFn: (draft: Draft<TeamSettings>) => void) => void {
  const { atoms } = useTeamControlContext();
  const dispatch = useSetAtom(atoms.teamSettingsAtom);

  return useCallback(
    (draftFn: (draft: Draft<TeamSettings>) => void) => {
      dispatch({ type: 'overwrite', updateDraft: draftFn });
    },
    [dispatch],
  );
}

/**
 * @deprecated Use `useFeatureStateMutation` in `@/modules/TeamSettings/hooks/useFeatureStateMutation.ts` instead
 */
export function useSettingMutation() {
  const { t } = useTranslation();

  const { atoms } = useTeamControlContext();
  const teamMetadata = useAtomValue(atoms.teamMetadataAtom);
  const dispatch = useSetAtom(atoms.teamSettingsAtom);

  return useMutation(
    async (payload: Array<SettingMutationPayload>) => {
      if (teamMetadata?.team_id) {
        const body = payload.map(({ settingKey, updateKey, value, additionalSettings }) => ({
          setting_type: updateKey ?? settingKey, // NOTE: should not put `settingKey` here, this issue will be resolved in `useFeatureStateMutation`
          update_status: value,
          additional_settings: additionalSettings,
        }));
        return updateTeamSettings(teamMetadata?.team_id, body);
      }
      throw new Error('Unexpected error') as never;
    },
    {
      mutationKey: mutationKeyMap.featureState,
      onMutate: (payload: Array<SettingMutationPayload>) => {
        dispatch({
          type: 'update',
          updateDraft: (draft) => {
            payload.forEach(({ settingKey, updateMode, value, updateSettingItem }) => {
              const setting = draft[settingKey];
              if (setting) {
                if (updateSettingItem) {
                  draft[settingKey] = updateSettingItem;
                } else if (updateMode) {
                  setting.mode = value;
                } else {
                  setting.value = value;
                }
              }
            });
          },
        });
      },
      onSuccess: () => {
        dispatch({ type: 'save' });
      },
      onError: (error) => {
        console.log(error);
        showErrorMessageOnRailsPage(t('common:unexpectedError'));
        dispatch({ type: 'rollback' });
      },
    },
  );
}

/**
 * @deprecated Use `useFeatureGranularControlValue` in `@/modules/TeamSettings/hooks/useFeatureGranularControlValue.ts` instead
 */
export function useFeatureGranularControl<Key extends GranularControlKey = GranularControlKey>(controlKey: Key) {
  const { atoms } = useTeamControlContext();

  const featureGranularControlAtom = selectAtom(
    atoms.granularControlAtom,
    useCallback((granularControl) => granularControl[controlKey], [controlKey]),
    isEqual,
  );

  return useAtomValue(featureGranularControlAtom) ?? null;
}

export function useFeatureGranularControlDetailSetting<Key extends GranularControlDetailSettingKey = GranularControlDetailSettingKey>(
  settingKey: Key,
) {
  const { atoms } = useTeamControlContext();

  const featureGranularControlAtom = selectAtom(
    atoms.granularControlAtom,
    useCallback((granularControl) => granularControl[settingKey], [settingKey]),
    isEqual,
  );

  return useAtomValue(featureGranularControlAtom) ?? null;
}

/**
 * @deprecated Use `useFeatureGranularControlMutation` in `@/modules/TeamSettings/hooks/useFeatureGranularControlMutation.ts` instead
 */
export function useGranularMutation() {
  const { t } = useTranslation();
  const { atoms } = useTeamControlContext();
  const teamMetadata = useAtomValue(atoms.teamMetadataAtom);
  const dispatch = useSetAtom(atoms.granularControlAtom);

  return useMutation(
    async (mutationPayload: GranularMutationPayload) => {
      if (teamMetadata?.team_id) {
        switch (mutationPayload.type) {
          case 'normal': {
            const { controlKey, value, settingKey, settingValue } = mutationPayload;

            const payload: UpdateGranularControlPayload = {
              [controlKey]: value,
              ...(settingKey && settingValue ? { [settingKey]: settingValue } : {}),
            };
            return updateTeamGranularControl(teamMetadata.team_id, payload);
          }
          case 'grant': {
            const { controlKey, value } = mutationPayload;
            const payload: UpdateGrantGranularControlPayload = { [controlKey]: value };
            return updateTeamGrantGranularControl(teamMetadata.team_id, payload);
          }
        }
      }
      throw new Error('Unexpected error') as never;
    },
    {
      mutationKey: [queryMainKey, 'mutateGranular'],
      onMutate: (mutatePayload) => {
        dispatch({
          type: 'update',
          updateDraft: (draft) => {
            const setting = draft[mutatePayload.controlKey];
            if (setting) {
              switch (mutatePayload.type) {
                case 'normal': {
                  const { type, value } = mutatePayload;
                  setting[type] = value;
                  break;
                }
                // TODO: better types ?
                // eslint-disable-next-line sonarjs/no-duplicated-branches
                case 'grant': {
                  const { type, value } = mutatePayload;
                  setting[type] = value;
                }
              }
            }

            if (mutatePayload.type === 'normal' && mutatePayload.settingKey && mutatePayload.settingValue) {
              const detailSettingDraft = draft[mutatePayload.settingKey];
              if (detailSettingDraft) {
                detailSettingDraft.normal = mutatePayload.settingValue;
              }
            }
          },
        });
      },
      onSuccess: () => {
        dispatch({ type: 'save' });
      },
      onError: (error) => {
        console.log(error);
        showErrorMessageOnRailsPage(t('common:unexpectedError'));
        dispatch({ type: 'rollback' });
      },
    },
  );
}

/**
 * @deprecated Use `useRefreshSettings` instead
 */
export function useRefreshSetting<Key extends SettingKey = SettingKey>(settingKey: Key) {
  const { atoms, teamKind } = useTeamControlContext();
  const dispatch = useSetAtom(atoms.teamSettingsAtom);

  return useCallback(async () => {
    const response = await getSingleSetting(settingKey);
    const newSettingValue = response[teamKind]?.team_settings[settingKey];

    if (newSettingValue) {
      dispatch({
        type: 'overwrite',
        updateDraft: (draft) => {
          draft[settingKey] = newSettingValue;
        },
      });
    }
  }, [dispatch, settingKey, teamKind]);
}

export function useRefreshSettings<Key extends SettingKey = SettingKey>(...settingKeys: Array<Key>) {
  const { atoms, teamKind } = useTeamControlContext();
  const dispatch = useSetAtom(atoms.teamSettingsAtom);

  return useCallback(async () => {
    const response = await getMultipleSetting(settingKeys);
    const newSettingValues: Pick<TeamSettings, Key> = response[teamKind]?.team_settings ?? {};

    dispatch({
      type: 'overwrite',
      updateDraft: (draft) => {
        settingKeys.forEach((key) => {
          draft[key] = newSettingValues[key];
        });
      },
    });
  }, [dispatch, settingKeys, teamKind]);
}

// ref: https://github.com/CodeSeven/toastr
type ToastrMessageOptions = {
  timeOut?: number;
};
type Toastr = {
  info(message: string, title?: string, options?: ToastrMessageOptions): void;
  warning(message: string, title?: string, options?: ToastrMessageOptions): void;
  success(message: string, title?: string, options?: ToastrMessageOptions): void;
  error(message: string, title?: string, options?: ToastrMessageOptions): void;
  remove(): void;
  clear(): void;
};

type JQuery = {
  (query: string): {
    tab(command: 'show'): void;
    addClass(classNameString: string): void;
    removeClass(classNameString: string): void;
    css(property: string, value: string): void;
  };
};

type RailsWindow = typeof parent & { toastr?: Toastr; $?: JQuery };
export function getRailsWindow(): RailsWindow {
  return parent as RailsWindow;
}

export function getToastrFromRails(): Toastr | null {
  const { toastr } = getRailsWindow();
  if (toastr) return toastr;
  return null;
}

/**
 * @deprecated Use `useTeamMetadata` from `@/modules/TeamSettings/hooks/useTeamMetadata.ts` instead
 */
export function useTeamMetadata() {
  const { atoms } = useTeamControlContext();

  return useAtomValue(atoms.teamMetadataAtom);
}

/**
 * File transfer has no mode before (and the mode value is `'0'`, also means enable both upload/download),
 * but we're add more mode (`'1', '2', '3'`) and deprecated the old mode value `'0'`,
 * so we need to transform `'0'` to `'3'`
 */
export function handleOldFileTransferModeValue(contextValue: FeatureItemContextValue): FeatureItemContextValue {
  const { value: oldModeValue, ...otherContextValue } = contextValue;
  const value = oldModeValue === '0' ? fileTransferMode.both : oldModeValue;
  return { ...otherContextValue, value };
}

export function useTeamDetail(teamKind: Exclude<AvailableTeamKind, 'sos'>): TeamDetail | null;
export function useTeamDetail(teamKind: 'sos'): SOSTeamDetail | null;
export function useTeamDetail(teamKind: AvailableTeamKind) {
  const { teamDetailSet } = useSettingControlContext();
  return teamDetailSet[teamKind] ?? null;
}

export function getParentQuery(queryKey: string): string | null {
  const urlParams = new URLSearchParams(parent.location.search);
  return urlParams.get(queryKey);
}

export function getTeamTypeFromParentQuery(): FeatureCategory | null {
  const teamType = getParentQuery('teamType');
  switch (teamType) {
    case 'unattended':
    case 'attended': {
      return teamType;
    }
    default: {
      return null;
    }
  }
}

export function getTargetFromParentQuery(): string | null {
  return getParentQuery('target');
}

export function getMsgFromParentQuery(): string | null {
  return getParentQuery('msg');
}

export function getParentQueryParams() {
  return {
    msg: getMsgFromParentQuery(),
    target: getTargetFromParentQuery(),
    teamType: getTeamTypeFromParentQuery(),
  };
}

export function isSRSEnterprise(metadata: TeamMetadata | undefined | null): boolean {
  const matchedPlan: Array<string> = ['enterprise'];

  return typeof metadata !== 'undefined' && metadata !== null && matchedPlan.includes(metadata.plan);
}

/**
 * ref: be-app
 *      app/views/pcp3.0/users/account_info_tab/_team.html.slim `=render 'users/account_info_tab/team/sos_plus'`
 *      app/models/seat.rb is_sos_w_mobile_pcs
 *
 * SOS Enterprise, SOS Teams, SOS plus series (SOS with Computers, SOS Unlimited)
 */
export function isSOSEnterprise(metadata: TeamMetadata | undefined | null): boolean {
  const matchedPlan: Array<string> = ['enterprise', 'teams', 'plus', 'unlimited'];

  return (
    typeof metadata !== 'undefined' &&
    metadata !== null &&
    metadata.role === 'owner' &&
    (matchedPlan.includes(metadata.plan) || metadata.seat_kind === 'trial-commercial-pcs')
  );
}

export function isNewSOSPlus(metadata: TeamMetadata | undefined | null): boolean {
  const matchedPlan: Array<string> = [
    'ste_lite_sos_plus',
    'ste_lite_sos_basic',
    ...(featureControl.getToggle('PCP_2061__TeamSettings__SOS_Unlimited_team_computer_quota_hardcode_adjustment')
      ? ['ste_lite_sos_unlimited']
      : []),
  ];

  return typeof metadata !== 'undefined' && metadata !== null && matchedPlan.includes(metadata.plan);
}

export function showSOSEnterpriseLayout(metadata: TeamMetadata | undefined | null): boolean {
  return isSOSEnterprise(metadata);
}

export function showSRSEnterpriseLayout(metadata: TeamMetadata | undefined | null): boolean {
  return isSRSEnterprise(metadata);
}

export function showNewSOSPlusLayout(metadata: TeamMetadata | undefined | null): boolean {
  return isNewSOSPlus(metadata);
}

/**
 * Check for `srs` team kind only.
 *
 * If the team kind is `ste` then no need to check with this function
 */
export function hasGranularControl(metadata: TeamMetadata | undefined | null): boolean {
  return (
    isSRSEnterprise(metadata) ||
    (isNewSOSPlus(metadata) &&
      (metadata?.plan === 'ste_lite_sos_plus' ||
        (featureControl.getToggle('PCP_2061__TeamSettings__SOS_Unlimited_team_computer_quota_hardcode_adjustment') &&
          metadata?.plan === 'ste_lite_sos_unlimited')))
  );
}

export function useTourState(tourKind: TourKind) {
  const tourStateMap = useAtomValue(tourStateMapAtom);
  const [isOpen, setIsOpen] = useAtom(tourStateMap[tourKind]);

  return {
    isOpen,
    open: () => {
      setIsOpen(true);
    },
    close: () => {
      setIsOpen(false);
      localStorage.setItem(tourKeys[tourKind], 'true');
    },
  };
}

export function useTeamSettingsMachine() {
  const { machineAtom } = useSettingControlContext();
  return useAtom(machineAtom);
}

export function useTabState(tabKind: TabKind): Required<Pick<RippleTabsProps, 'index' | 'onChange'>> {
  const tabStateMap = useAtomValue(tabStateMapAtom);
  const [index, setIndex] = useAtom(tabStateMap[tabKind]);

  return {
    index,
    onChange: (index: number) => {
      setIndex(index);
    },
  };
}

export function useLayoutType(): LayoutType {
  const { teamKinds, teamMetadataSet } = useSettingControlContext();
  if (showSOSEnterpriseLayout(teamMetadataSet.sos)) return 'SOS Enterprise';
  if (teamKinds.length > 1) return 'multiple';
  return 'single';
}

export function useSeatPermission(permissionKey: SeatPermissionsKey): boolean {
  const { seatPermissions } = useTeamControlContext();

  return Boolean(seatPermissions[permissionKey]);
}

export function createSettingKeyMap<T extends EditableSettingType, U extends Readonly<[T, ...Array<T>]>>(settingKeyList: U) {
  return z.enum<T, U>(settingKeyList).enum;
}

export function canAccessSetting(setting: CommonSettingState | undefined | null): boolean {
  return setting !== null && setting !== undefined && setting.accessibility === 'read_write';
}

export function hasSearchParamsTarget({ targetKey }: { targetKey: string }) {
  const searchParams = new URLSearchParams(window.parent.location.search);
  return searchParams.get('target') === targetKey;
}
/**
 * Scrolls to a target section in Team Setting based on the URL query parameter.
 * @param {string} options.targetKey - The target key to match with the URL query parameter.
 * @returns {Object} - The ref object that can be used to scroll to the target section.
 * @example routers.push('/account_info?target=service_desk#team_tab');
 * @example const { targetRef } = useScrollToTargetSectionByUrlQuery({ targetKey: 'service_desk' });
 */
export function useScrollToTargetSectionByUrlQuery({ targetKey }: { targetKey: string }) {
  const hasTargetKey = hasSearchParamsTarget({ targetKey });
  const targetRef = useRef<HTMLDivElement>(null);
  const teamDetail = useQuery([queryMainKey, 'getTeamDetail']);
  const teamSettingsQuery = useQuery([queryMainKey, 'getTeamSettings']);
  const isTeamSettingContainerReady = teamDetail.status === 'success' && teamSettingsQuery.status === 'success';

  useEffect(() => {
    if (hasTargetKey && targetRef.current && isTeamSettingContainerReady) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [targetKey, isTeamSettingContainerReady, hasTargetKey]);

  return { targetRef };
}

/**
 * Only Enterprise team (4-10 & New STE `ste_custom`), Splashtop team (4-15 ~ 23) and New SOS Plus team (4-14) has granular control
 *
 * Note that Splashtop team & New SOS Plus team are only have "Attended access" granular control
 */
export function getGranularControlAvailableTeamKind(teamMetadataSet: TeamMetadataSet): GranularControlAvailableTeamKind | null {
  const availableTeamKinds = featureControl.getToggle('PCP_506__TeamSettings_RoleManagement')
    ? (['srs', 'ste_custom', 'splashtop'] as const)
    : (['srs'] as const);

  return availableTeamKinds.find((teamKind) => teamMetadataSet[teamKind] !== undefined) ?? null;
}

/**
 * Get available granular control feature keys for the team.
 * Usually we use the keys to fetch the granular control setting states.
 */
export function getGranularControlFeatures(featureStateMap: TeamSettings | undefined): Array<GranularControlKey> | null {
  return featureStateMap?.granular_control?.available_features ?? null;
}
