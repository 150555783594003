import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import { useErrorModal } from '@/modules/ServiceDesk/SurveyForm/Editor';
import { useCustomImageFile, useResetCustomImageFile } from '@/modules/ServiceDesk/SurveyForm/Editor/hooks';
import { viewSurvey } from '@/services/serviceDesk/surveys/viewSurvey';
import { imageUrlToBase64 } from '@/utils/image';

const SURVEY_LIST_PATH = '/service_desk/surveys';

type UseInitializeSurvey = {
  teamId?: number;
  surveyId: string;
};

export const useInitializeSurvey = ({ teamId, surveyId }: UseInitializeSurvey) => {
  const { t } = useTranslation();
  const routers = useRouter();

  const { openErrorModal, closeErrorModal } = useErrorModal();

  const [customImageFile, setCustomImageFile] = useCustomImageFile();
  const resetCustomImageFile = useResetCustomImageFile();
  const customImageBase64 = customImageFile.url;

  const {
    data: originalSurvey,
    isInitialLoading: isLoadingOriginalSurvey,
    isError: isOriginalSurveyError,
  } = useQuery({
    queryKey: ['survey', 'get-survey-for-initial-data', teamId, surveyId],
    enabled: typeof teamId !== 'undefined' && surveyId !== null,
    cacheTime: 0, // Don't cache data to prevent using outdated data
    queryFn: async () => {
      if (typeof teamId === 'undefined' || !surveyId) {
        throw new Error('Dependency not passed to query');
      }
      return await viewSurvey({ teamId, surveyId });
    },
    onError: (error) => {
      if (error) {
        openErrorModal({
          title: t('survey:survey_does_not_exist'),
          message: t('survey:the_survey_you_were_working_on_does_not_exist'),
          onClickPrimaryButton: () => {
            closeErrorModal();
            routers.push(SURVEY_LIST_PATH);
          },
        });
      }
    },
  });

  const originalImageUrl = originalSurvey?.brand_image_url;

  useEffect(
    function initializeBrandImageInBase64Format() {
      if (originalImageUrl) {
        const updateCustomImage = (base64String: string) => {
          setCustomImageFile({
            name: '',
            url: base64String,
          });
        };
        imageUrlToBase64(originalImageUrl, updateCustomImage);
      } else {
        resetCustomImageFile();
      }
    },
    [originalImageUrl, setCustomImageFile, resetCustomImageFile],
  );

  return { originalSurvey, isLoadingOriginalSurvey, isOriginalSurveyError, customImageBase64 };
};
