import { useQuery } from '@tanstack/react-query';

import { featureControl } from '@/feature/toggle';
import { useAttendedTeamInformation, useUnattendedTeamInformation } from '@/models/TeamInformation';
import {
  shouldShowServiceDeskChannel,
  shouldShowServiceDeskChannelByEnterprise_deprecated,
  shouldShowServiceDeskChannelBySOS_deprecated,
} from '@/modules/ServiceDesk';
import { Team } from '@/services/common/types';
import { type MemberStatus, infoQueryService } from '@/services/users/infoQueryService';
import { compareToCurrentDate } from '@/utils/useTeamInformation';

type UseTeamInfoForChannelsLegacy = {
  teamType: 'sos' | 'srs' | '';
  teamId: number;
  memberStatus: Team['member_status'];
  isExpired: boolean;
  canAccessTeamSetting: boolean;
};
/**
 * @deprecated The data is from the basic profile API which should be migrate to the info query API, use {@link useTeamInfoForChannelsQuery} instead
 * TODO: Remove this function along with feature toggle: PCP_2760_modify_permission_checking_for_new_unified_product
 */
export function useTeamInfoForChannels_deprecated(): UseTeamInfoForChannelsLegacy {
  const attendedTeamInformation = useAttendedTeamInformation();
  const unattendedTeamInformation = useUnattendedTeamInformation();

  if (attendedTeamInformation && shouldShowServiceDeskChannelBySOS_deprecated(attendedTeamInformation)) {
    const {
      team_id: teamId,
      member_status: memberStatus,
      expires_at,
      team_member_permissions: { team_settings_management },
    } = attendedTeamInformation;
    return {
      teamType: 'sos',
      teamId,
      memberStatus,
      isExpired: compareToCurrentDate(expires_at),
      canAccessTeamSetting: team_settings_management ?? false,
    };
  } else if (unattendedTeamInformation && shouldShowServiceDeskChannelByEnterprise_deprecated(unattendedTeamInformation)) {
    const {
      team_id: teamId,
      member_status: memberStatus,
      expires_at,
      team_member_permissions: { team_settings_management },
    } = unattendedTeamInformation;
    return {
      teamType: 'srs',
      teamId,
      memberStatus,
      isExpired: compareToCurrentDate(expires_at),
      canAccessTeamSetting: team_settings_management ?? false,
    };
  }

  return {
    teamType: '',
    teamId: -1,
    memberStatus: 'disabled',
    isExpired: true,
    canAccessTeamSetting: false,
  };
}

type UseTeamInfoForChannels = {
  teamType: 'splashtop' | 'ste_custom' | 'sos' | 'srs' | '';
  teamId: number;
  memberStatus: MemberStatus;
  isExpired: boolean;
  canAccessTeamSetting: boolean;
};
/** Get the necessary team info if user is able to access channels */
export const useTeamInfoForChannelsQuery = () => {
  const attendedTeamInformation = useAttendedTeamInformation();
  const unattendedTeamInformation = useUnattendedTeamInformation();
  return useQuery({
    queryKey: ['info-query', 'channel'],
    queryFn: async () =>
      infoQueryService.execute({
        team_info: ['is_expired'],
        team_permissions: ['sos_service_desk'],
        team_member_info: ['role', 'member_status', 'group_scope'],
        team_member_permissions: ['team_settings_management', 'attended_access', 'technician_manager'],
      }),
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 5 * 60 * 1000, // 5 minutes
    enabled: featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product'),
    select: (data): UseTeamInfoForChannels => {
      // Handle the abnormal case that if a user has duplicated service desk permission from both sos and srs, we should use sos first
      if (
        data.sos &&
        attendedTeamInformation &&
        shouldShowServiceDeskChannel({
          role: data.sos.team_member_info.role,
          groupScope: data.sos.team_member_info.group_scope,
          memberStatus: data.sos.team_member_info.member_status,
          sosServiceDesk: data.sos.team_permissions.sos_service_desk,
          attendedAccess: data.sos.team_member_permissions.attended_access,
          technicianManager: data.sos.team_member_permissions.technician_manager,
        })
      ) {
        return {
          teamType: 'sos',
          teamId: attendedTeamInformation.team_id,
          memberStatus: data.sos.team_member_info.member_status,
          isExpired: data.sos.team_info.is_expired,
          canAccessTeamSetting: data.sos.team_member_permissions.team_settings_management,
        };
      }
      // Handle the rest case
      return Object.entries(data).reduce(
        (result, [teamType, { team_info, team_member_permissions, team_member_info, team_permissions }]) => {
          if (
            unattendedTeamInformation &&
            shouldShowServiceDeskChannel({
              role: team_member_info.role,
              groupScope: team_member_info.group_scope,
              memberStatus: team_member_info.member_status,
              sosServiceDesk: team_permissions.sos_service_desk,
              attendedAccess: team_member_permissions.attended_access,
              technicianManager: team_member_permissions.technician_manager,
            })
          ) {
            return {
              teamType: teamType as UseTeamInfoForChannels['teamType'],
              teamId: unattendedTeamInformation.team_id,
              memberStatus: team_member_info?.member_status,
              isExpired: team_info.is_expired,
              canAccessTeamSetting: team_member_permissions.team_settings_management,
            };
          }
          return result;
        },
        {
          teamType: '',
          teamId: -1,
          memberStatus: 'disabled',
          isExpired: true,
          canAccessTeamSetting: false,
        } as UseTeamInfoForChannels,
      );
    },
  });
};
