import { atom } from 'jotai';
import { atomEffect } from 'jotai-effect';
import { atomWithImmer } from 'jotai-immer';

import { ACKNOWLEDGED_TYPES, ALL_GROUP_ID } from '@/modules/AntivirusSessions/constants';
import {
  ACTION_OPTIONS_DEFAULT_STATE,
  DEFAULT_GROUP_ID,
  DEFAULT_LAST_DAY,
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  FROM_OTHER_GROUP_ID,
  SOFTWARE_FILTER_OPTIONS_MAP,
} from '@/modules/AntivirusSessions/constants';
import type { AcknowledgedType, AdditionGroupMap, FilterGroupValue, LastDayRange } from '@/modules/AntivirusSessions/types';
import type { ActionOptionsKey, SoftwareOptionKey } from '@/modules/AntivirusSessions/types';

export const rowSelectionStateAtom = atom<Record<number, boolean>>({});
export const selectedLastDayAtom = atom<LastDayRange>(DEFAULT_LAST_DAY);
export const searchKeywordAtom = atom<string>('');
export const acknowledgeStateAtom = atom<'' | true | false>('');
export const selectedActionStateMapAtom = atomWithImmer<Record<ActionOptionsKey, boolean>>(ACTION_OPTIONS_DEFAULT_STATE);
export const selectedSoftwareStateMapAtom =
  atomWithImmer<Record<SoftwareOptionKey, { displayName: string; isChecked: boolean }>>(SOFTWARE_FILTER_OPTIONS_MAP);
export const isExportingAtom = atom<boolean>(false);
export const pageIndexAtom = atom<number>(DEFAULT_PAGE_INDEX);
export const pageSizeAtom = atom<number>(DEFAULT_PAGE_SIZE);
export const filterGroupAtom = atom<FilterGroupValue>(ALL_GROUP_ID);
export const filterAcknowledgeAtom = atom<AcknowledgedType>(ACKNOWLEDGED_TYPES.ALL);
export const isAppliedFiltersAtom = atom<boolean>((get) => {
  const groupId = get(filterGroupAtom);
  const acknowledge = get(filterAcknowledgeAtom);
  const selectedSoftwareStringForApi = get(selectedSoftwareStringForApiAtom);
  const selectedActionStringForApi = get(selectedActionStringForApiAtom);
  return (
    groupId !== ALL_GROUP_ID ||
    acknowledge !== ACKNOWLEDGED_TYPES.ALL ||
    selectedSoftwareStringForApi !== '' ||
    selectedActionStringForApi !== ''
  );
});

export const resetFiltersAtom = atom(null, (_, set) => {
  set(filterGroupAtom, ALL_GROUP_ID);
  set(filterAcknowledgeAtom, ACKNOWLEDGED_TYPES.ALL);
  set(pageIndexAtom, DEFAULT_PAGE_INDEX);
  set(selectedSoftwareStateMapAtom, SOFTWARE_FILTER_OPTIONS_MAP);
  set(selectedActionStateMapAtom, ACTION_OPTIONS_DEFAULT_STATE);
});

export const resetKeywordAtom = atom(null, (_, set) => {
  set(searchKeywordAtom, '');
});

const selectedSoftwareStringForApiAtom = atom((get) => {
  const softwareFilterStateMap = get(selectedSoftwareStateMapAtom);
  return Object.entries(softwareFilterStateMap)
    .filter(([, { isChecked }]) => isChecked)
    .map(([software]) => software)
    .join(',');
});

export const selectedActionStringForApiAtom = atom((get) => {
  const selectedActionStateMap = get(selectedActionStateMapAtom);

  return Object.entries(selectedActionStateMap)
    .filter(([, isSelected]) => isSelected)
    .map(([action]) => action)
    .join(',');
});

export const queryLogsParamsAtom = atom((get) => {
  const keyword = get(searchKeywordAtom);
  const pageIndex = get(pageIndexAtom);
  const pageSize = get(pageSizeAtom);
  const selectedLastDay = get(selectedLastDayAtom);
  const filterGroup = get(filterGroupAtom);
  const filterAcknowledge = get(filterAcknowledgeAtom);
  const selectedSoftwareStringForApi = get(selectedSoftwareStringForApiAtom);
  const selectedActionStringForApi = get(selectedActionStringForApiAtom);
  return {
    pageSize,
    pageIndex,
    selectedLastDay,
    filters: {
      keyword,
      groupId: filterGroup,
      acked: filterAcknowledge,
      softwares: selectedSoftwareStringForApi,
      threat_actions: selectedActionStringForApi,
    },
  };
});

export const additionGroupMapAtom = atom<AdditionGroupMap>({
  [ALL_GROUP_ID]: 'All Groups',
  [DEFAULT_GROUP_ID]: 'Default Group',
  [FROM_OTHER_GROUP_ID]: 'From Other Group',
});

export const resetPageIndexOnChangeEffect = atomEffect((get, set) => {
  get(selectedLastDayAtom);
  get(filterGroupAtom);
  get(selectedSoftwareStringForApiAtom);
  get(selectedActionStateMapAtom);
  get(filterAcknowledgeAtom);
  get(searchKeywordAtom);
  get(pageSizeAtom);

  set(pageIndexAtom, DEFAULT_PAGE_INDEX);
});
