import { useQuery } from '@tanstack/react-query';

import { featureControl } from '@/feature/toggle';
import { useTeamInformation } from '@/models/TeamInformation';
import { infoQueryService } from '@/services/users';

export const usePreferencePolicyAccess = () => {
  const { teamKind, role } = useTeamInformation();

  const infoQuery = useQuery({
    queryKey: ['preference policy', 'info'],
    queryFn: () =>
      infoQueryService.execute({
        team_info: ['is_expired'],
        team_permissions: ['preference_policy'],
      }),
    enabled: featureControl.getToggle('PCP_1134__Policy_management'),
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 5 * 60 * 1000, // 5 minutes
  });

  const isTeamSupported = ['sba', 'srs', 'msp'].includes(teamKind);
  const isRoleSupported = role === 'manager' || role === 'owner';
  const isTeamAccessible = Boolean(infoQuery.data?.[teamKind]?.team_permissions.preference_policy);
  const isExpired = Boolean(infoQuery.data?.[teamKind]?.team_info.is_expired);

  return {
    isFetched: infoQuery.isFetched,
    canAccess: isTeamSupported && isRoleSupported && isTeamAccessible,
    isExpired,
  };
};
