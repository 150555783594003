import React, { Fragment, useState } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import styled from '@emotion/styled';
import getTime from 'date-fns/getTime';
import parseISO from 'date-fns/parseISO';
import { useTranslation } from 'next-i18next';

import Alert from '@/components/Alert';
import { Anchor, AnchorWithUnderLine } from '@/components/Anchor';
import Button from '@/components/Button';
import MessageModal from '@/components/MessageModal';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@/components/Modal';
import { featureControl } from '@/feature/toggle';
import type { TeamKind } from '@/services/common/types';
import { leaveTeam as leaveTeamAPIForSOS } from '@/services/sos';
import { leaveTeam as leaveTeamAPI } from '@/services/team';
import { ContactSalesModal, useContactSalesModal } from '@/showcase';
import { postMessageToRails } from '@/utils/postMessageToRails';
import useSeatInformation from '@/utils/useSeatInformation';
import useTeamInformation from '@/utils/useTeamInformation';

const singleSpace = <span>&nbsp;</span>;

const leftDays = (expiresAt: string | null) => {
  if (!expiresAt) return 0;
  const expiresDate = parseISO(expiresAt + 'Z');
  return Math.ceil((getTime(expiresDate) - Date.now()) / 86400_000);
};

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type TeamStatusProps = {
  teamType?: TeamKind;
  isInIframe?: boolean;
  sx?: {
    box?: BoxProps;
  };
};

const TeamStatus = ({ teamType, isInIframe = false, sx }: TeamStatusProps) => {
  const { t } = useTranslation(['common', 'team']);
  const teamInformation = useTeamInformation(teamType ? teamType : undefined);
  const srsInfo = useTeamInformation('srs');
  const sosInfo = useTeamInformation('sos');

  const seatInformation = useSeatInformation();

  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isQuitModalOpen, setIsQuitModalOpen] = useState(false);
  const [isLeavingTeam, setIsLeavingTeam] = useState(false);
  const contactSalesModal = useContactSalesModal();

  if (!teamInformation) {
    return null;
  }

  const sosTeamNotMatch =
    teamType === 'sos' && srsInfo?.plan === 'plus_from_sos' && !(sosInfo?.plan === 'plus' || sosInfo?.plan === 'unlimited');

  if (sosTeamNotMatch) {
    return null;
  }

  const { teamId, memberId, role, isExpired, isResell, contactSales, seatKind, expiresAt, memberStatus, currentTeam, plan } =
    teamInformation;
  const { onShelf, isDefault, isRecurring, hasAutoRenew, canTrial, canBuyProduct, buyUrl, trialUrl } = seatInformation;

  if (isDefault) return null;

  let productName = '';

  if (teamType === 'sos') productName = 'Splashtop SOS';
  else {
    switch (currentTeam) {
      case 'sba':
        productName = 'Splashtop Business Access';
        if (plan === 'solo') {
          productName = 'Splashtop Business Access Solo';
        } else if (plan === 'pro') {
          productName = 'Splashtop Business Access Pro';
        }
        break;
      case 'msp':
        productName = 'Splashtop Remote Support';
        break;
      case 'srs':
        productName = 'Splashtop Remote Support';
        if (plan === 'basic') {
          productName = 'Splashtop Remote Support Basic';
        } else if (plan === 'plus') {
          productName = 'Splashtop Remote Support Plus';
        } else if (plan === 'premium') {
          productName = 'Splashtop Remote Support Premium';
        } else if (plan === 'enterprise') {
          productName = 'Splashtop Enterprise';
        } else if (plan === 'ste_lite_sos_basic') {
          productName = 'Splashtop SOS';
        } else if (plan === 'ste_lite_sos_plus') {
          productName = 'Splashtop SOS Plus';
        } else if (
          featureControl.getToggle('PCP_2061__TeamSettings__SOS_Unlimited_team_computer_quota_hardcode_adjustment') &&
          plan === 'ste_lite_sos_unlimited'
        ) {
          productName = 'Splashtop SOS Unlimited';
        }
        break;
      default:
        productName = '';
        break;
    }
  }

  const openMessageModal = () => {
    if (isInIframe) postMessageToRails({ type: 'loading', data: { loading_type: 'navMask' } });
    setIsMessageModalOpen(true);
  };

  const openQuitModal = () => {
    if (isInIframe) postMessageToRails({ type: 'loading', data: { loading_type: 'navMask' } });
    setIsQuitModalOpen(true);
  };

  const openResellerOrContactSalesModal = ({ title }: { title: string }) => {
    if (isInIframe) postMessageToRails({ type: 'loading', data: { loading_type: 'navMask' } });

    contactSalesModal.open({ title });
  };

  const closeMessageModal = () => {
    if (isInIframe) postMessageToRails({ type: 'load_complete' });
    setIsMessageModalOpen(false);
  };

  const closeQuitModal = () => {
    if (isInIframe) postMessageToRails({ type: 'load_complete' });
    setIsQuitModalOpen(false);
  };

  const leaveTeam = () => {
    if (teamId && memberId) {
      setIsLeavingTeam(true);
      const leaveTeamAPIFromTeamType = teamType === 'sos' ? leaveTeamAPIForSOS : leaveTeamAPI;
      leaveTeamAPIFromTeamType(teamId, memberId)
        .then((status) => {
          if (status) {
            if (isInIframe) window.top?.location.reload();
            else location.reload();
          } else setIsLeavingTeam(false);
        })
        .catch(() => {
          setIsLeavingTeam(false);
        });
    }
  };

  const messages = [];
  if (isExpired) {
    if (role === 'owner') {
      seatKind.includes('trial')
        ? messages.push(t('team:status.trialExpired', { productName }))
        : messages.push(t('team:status.subscriptionExpired', { productName }));
      messages.push(<>{singleSpace}</>);
      if (isResell || contactSales) {
        const title = t('team:status.buyNow');
        messages.push(
          <Button color="success" px="12px" py="6px" onClick={() => openResellerOrContactSalesModal({ title })}>
            {title}
          </Button>,
        );
      } else if (onShelf && !isRecurring) {
        messages.push(
          <Button
            color="success"
            as="a"
            px="12px"
            py="6px"
            href={canBuyProduct ? buyUrl : '#'}
            onClick={!canBuyProduct ? openMessageModal : undefined}
            target={isInIframe ? '_parent' : '_self'}
          >
            {t('team:status.buyNow')}
          </Button>,
        );
        if (canTrial)
          messages.push(
            <>
              {singleSpace}
              <span>{t('common:or')}</span>
              {singleSpace}
              <AnchorWithUnderLine target={isInIframe ? '_parent' : '_self'} href={trialUrl}>
                {t('team:status.trialAgain')}
              </AnchorWithUnderLine>
            </>,
          );
      }
    } else {
      messages.push(t('team:status.teamExpiredMember'));
      if (!featureControl.getToggle('PCP_2010__Remove_QuitTeamSentence')) {
        messages.push(
          <>
            {singleSpace}

            <Anchor href="#" onClick={openQuitModal}>
              {`(${t('team:status.removeMyselfFromTeam')})`}
            </Anchor>
          </>,
        );
      }
    }
  } else if (role === 'owner' && seatKind.includes('trial') && (expiresAt || teamType === 'sos')) {
    if (expiresAt) {
      messages.push(t('team:status.trialWillExpire', { productName, leftDays: leftDays(expiresAt) }));
      if (isResell || contactSales) {
        const title = t('team:status.trialSubscribeNow');

        messages.push(
          <>
            {singleSpace}
            <Button color="success" px="12px" py="6px" onClick={() => openResellerOrContactSalesModal({ title })}>
              {title}
            </Button>
          </>,
        );
      } else if (onShelf) {
        messages.push(
          <>
            {singleSpace}
            <Button color="success" px="12px" py="6px" as="a" target={isInIframe ? '_parent' : '_self'} href={buyUrl}>
              {t('team:status.trialSubscribeNow')}
            </Button>
          </>,
        );
      }
    } else if (teamType === 'sos') {
      const title = t('team:status.subscribeForCommercialUse');

      messages.push(
        <Button
          color="success"
          px="12px"
          py="6px"
          as="a"
          target={isInIframe ? '_parent' : '_self'}
          onClick={contactSales ? () => openResellerOrContactSalesModal({ title }) : undefined}
          href={!contactSales ? buyUrl : undefined}
        >
          {title}
        </Button>,
      );
    }
  } else if (role === 'owner' && !isResell && expiresAt && leftDays(expiresAt) <= 30 && (!isRecurring || hasAutoRenew === false)) {
    messages.push(t('team:status.subscriptionLeftDays', { leftDays: leftDays(expiresAt) }));
    if (isRecurring && hasAutoRenew === false) {
      messages.push(
        <span>
          {singleSpace}(<AnchorWithUnderLine href="/account_info/#subscription_tab">{t('team:status.turnOnAutoRenew')}</AnchorWithUnderLine>
          )
        </span>,
      );
    } else if (onShelf) {
      const title = t('team:status.trialSubscribeNow');

      messages.push(
        <>
          {singleSpace}
          <Button
            color="success"
            px="12px"
            py="6px"
            as="a"
            target={isInIframe ? '_parent' : '_self'}
            onClick={contactSales ? () => openResellerOrContactSalesModal({ title }) : undefined}
            href={!contactSales ? buyUrl : undefined}
          >
            {title}
          </Button>
        </>,
      );
    }
  } else if (memberStatus === 'disabled') {
    role === 'owner' ? messages.push(t('team:status.ownerDisabled')) : messages.push(t('team:status.teamMemberDisabled'));
  }

  if (messages.length < 1) {
    return null;
  }

  return (
    <Box {...(sx?.box || {})}>
      <Alert marginTop={isInIframe ? '16px' : 0}>
        <MessageContainer>
          {messages.map((message, index) => (
            <Fragment key={index}>{message}</Fragment>
          ))}
        </MessageContainer>
        {/*
        Message Modal for One Team Only
      */}
        <MessageModal isOpen={isMessageModalOpen} message={t('team:status.oneTeamOnly')} closeModal={closeMessageModal} />
        {/*
        Quit Modal
      */}
        <Modal isOpen={isQuitModalOpen}>
          <ModalHeader title={t('team:quitThisTeamTitle')} closeModal={closeQuitModal} />
          <ModalBody>{t('team:quitThisTeamContent')}</ModalBody>
          <ModalFooter>
            <Box display="flex" justifyContent="flex-end">
              <Button color="primary" px="12px" py="6px" onClick={leaveTeam} disabled={isLeavingTeam}>
                {t('common:ok')}
              </Button>
              {singleSpace}
              <Button color="default" px="12px" py="6px" onClick={closeQuitModal} disabled={isLeavingTeam}>
                {t('common:cancel')}
              </Button>
            </Box>
          </ModalFooter>
        </Modal>

        <ContactSalesModal
          onClose={() => {
            if (isInIframe) postMessageToRails({ type: 'load_complete' });
          }}
        />
      </Alert>
    </Box>
  );
};

export default TeamStatus;
