import { useContext } from 'react';

import { useAtomValue } from 'jotai';

import { featureControl } from '@/feature/toggle';
import { teamControlAtomFamily, teamControlScopeAtom } from '@/modules/TeamSettings/atoms';
import { TeamControlContext } from '@/modules/TeamSettings/contexts';
import { TeamControlContextValue, TeamInfo, TeamMemberPermissions } from '@/modules/TeamSettings/types';

/**
 * Get the team control context.
 */
export const useTeamControlContext = featureControl.getToggle('PCP_506__TeamSettings_RoleManagement')
  ? useTeamControlContext_new
  : useTeamControlContext_deprecated;

export function useTeamControlContext_new(): TeamControlContextValue {
  const teamControlScope = useAtomValue(teamControlScopeAtom);
  const teamControlAtom = teamControlAtomFamily(teamControlScope);

  return useAtomValue(teamControlAtom);
}

export function useTeamControlContext_deprecated(): TeamControlContextValue {
  const teamControlContext = useContext(TeamControlContext);

  /**
   * Not used in the deprecated version.
   */
  const dummyTeamInfo: TeamInfo = {
    license_info: null,
    feature_id: NaN,
    feature_capacity: NaN,
  };

  /**
   * Not used in the deprecated version.
   */
  const dummyTeamMemberPermissions: TeamMemberPermissions = {
    emm_patch_opportunities: false,
    os_opportunities: false,
  };

  return {
    ...teamControlContext,
    teamInfo: dummyTeamInfo,
    teamMemberPermissions: dummyTeamMemberPermissions,
  };
}
