import { z } from 'zod';

import { granularControlDetailSettingKeys, granularControlKeys } from './constants';

/**
 * - `manager`: Admin
 * - `member`: Member
 * - `group_admin`: Group Admin // NOTE: This type is depend on the feature toggle `PCP_506__TeamSettings_RoleManagement`.
 */
export const granularControlRoleSchema = z.enum(['manager', 'member', 'group_admin']);
export const granularControlRoleEnum = granularControlRoleSchema.enum;
export type GranularControlRole = z.infer<typeof granularControlRoleSchema>;

export const granularControlSettingValueSchema = z.enum(['on', 'off']);
export const granularControlSettingSchema = createGranularControlSettingSchema(granularControlSettingValueSchema);
export type GranularControlSetting = z.infer<typeof granularControlSettingSchema>;

export const granularControlKeySchema = z.enum(granularControlKeys);
export const granularControlKeyEnum = granularControlKeySchema.enum;
export type GranularControlKey = z.infer<typeof granularControlKeySchema>;

export const granularControlDetailSettingKeySchema = z.enum(granularControlDetailSettingKeys);
export const granularControlDetailSettingKeyEnum = granularControlDetailSettingKeySchema.enum;
export type GranularControlDetailSettingKey = z.infer<typeof granularControlDetailSettingKeySchema>;
export const granularControlDetailSettingSchema = z
  .object({
    [granularControlDetailSettingKeyEnum.conn_perm_setting]: createGranularControlSettingSchema(
      z.object({
        setting: z.union([z.string(), z.literal(0), z.literal(1), z.literal(2)]),
        option: z.union([z.string(), z.null(), z.literal(1), z.literal(2)]),
      }),
    ),
  })
  .partial();
export type GranularControlDetailSetting = z.infer<typeof granularControlDetailSettingSchema>;

const granularControlMapShape: Record<GranularControlKey, typeof granularControlSettingSchema> = {
  attended_access: granularControlSettingSchema,
  file: granularControlSettingSchema,
  remote_print: granularControlSettingSchema,
  copy_paste: granularControlSettingSchema,
  required_2sv: granularControlSettingSchema,
  one_to_many: granularControlSettingSchema,
  command_prompt: granularControlSettingSchema,
  watermark: granularControlSettingSchema,
  remote_control: granularControlSettingSchema,
  conn_perm: granularControlSettingSchema,
  relay_recording: granularControlSettingSchema,
  sos_relay_recording: granularControlSettingSchema,
  relay_recording_access: granularControlSettingSchema,
  sos_relay_recording_access: granularControlSettingSchema,
  emm_patch_opportunities: granularControlSettingSchema,
  os_opportunities: granularControlSettingSchema,
  alert: granularControlSettingSchema,
  event_logs: granularControlSettingSchema,
  off_session_file: granularControlSettingSchema,
  file_transfer: granularControlSettingSchema,
  premium_tool: granularControlSettingSchema,
  antivirus: granularControlSettingSchema,
  endpoint_security: granularControlSettingSchema,
  inventory: granularControlSettingSchema,
  smart_action: granularControlSettingSchema,
  one_to_many_basic: granularControlSettingSchema,
};
export const granularControlMapSchema = z.object(granularControlMapShape).partial().and(granularControlDetailSettingSchema);
export type GranularControlMap = z.infer<typeof granularControlMapSchema>;

function createGranularControlSettingSchema<Schema extends z.ZodTypeAny>(settingValueSchema: Schema) {
  return z.record(granularControlRoleSchema, settingValueSchema);
}
