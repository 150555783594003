import { z } from 'zod';

export const DEFAULT_PAGE_INDEX = 1;
export const DEFAULT_PAGE_SIZE = 500; // The api maximum per_page_size is 500
export const DEFAULT_LAST_DAY = 7;
export const BITDEFENDER_2 = 'Bitdefender2';
export const ACKNOWLEDGED_TYPES = {
  ALL: 'all',
  ACKNOWLEDGED: 'acked',
  UNACKNOWLEDGED: 'unack',
} as const;
export const SOFTWARE_FILTER_OPTIONS = new Map([
  ['Windows Defender', false],
  ['Bitdefender', false],
  ['Avast', false],
  ['Symantec', false],
  ['ESET', false],
  ['Webroot', false],
  ['Shopos', false],
  ['TrendMicro', false],
  ['AVG', false],
  ['McAfee', false],
  ['Kaspersky', false],
  ['Bitdefender2', false],
]);

export const SOFTWARE_FILTER_OPTIONS_MAP = {
  windows_defender: { displayName: 'Windows Defender/Microsoft Security Essentials', isChecked: false },
  bitdefender: { displayName: 'Bitdefender', isChecked: false },
  avast: { displayName: 'Avast', isChecked: false },
  symantec: { displayName: 'Symantec', isChecked: false },
  eset: { displayName: 'ESET', isChecked: false },
  webroot: { displayName: 'Webroot', isChecked: false },
  shopos: { displayName: 'Shopos', isChecked: false },
  trendmicro: { displayName: 'TrendMicro', isChecked: false },
  avg: { displayName: 'AVG', isChecked: false },
  mcafee: { displayName: 'McAfee', isChecked: false },
  kaspersky: { displayName: 'Kaspersky', isChecked: false },
  bitdefender2: { displayName: 'Splashtop Antivirus', isChecked: false },
};

// TODO: Should be refactor to simpler version with zod
export const ACTION_OPTIONS_MAP = {
  unknown: 'unknown',
  detected: 'detected',
  cleaned: 'cleaned',
  quarantined: 'quarantined',
  removed: 'removed',
  allowed: 'allowed',
  blocked: 'blocked',
  clean_failed: 'clean_failed',
  quarantine_failed: 'quarantine_failed',
  remove_failed: 'remove_failed',
  allow_failed: 'allow_failed',
  abandoned: 'abandoned',
  block_failed: 'block_failed',
} as const;

export const actionOptionsEnum = z.union([
  z.literal(ACTION_OPTIONS_MAP.unknown),
  z.literal(ACTION_OPTIONS_MAP.detected),
  z.literal(ACTION_OPTIONS_MAP.cleaned),
  z.literal(ACTION_OPTIONS_MAP.quarantined),
  z.literal(ACTION_OPTIONS_MAP.removed),
  z.literal(ACTION_OPTIONS_MAP.allowed),
  z.literal(ACTION_OPTIONS_MAP.blocked),
  z.literal(ACTION_OPTIONS_MAP.clean_failed),
  z.literal(ACTION_OPTIONS_MAP.quarantine_failed),
  z.literal(ACTION_OPTIONS_MAP.remove_failed),
  z.literal(ACTION_OPTIONS_MAP.allow_failed),
  z.literal(ACTION_OPTIONS_MAP.abandoned),
  z.literal(ACTION_OPTIONS_MAP.block_failed),
]);

export const ACTION_OPTIONS_DEFAULT_STATE = {
  [ACTION_OPTIONS_MAP.unknown]: false,
  [ACTION_OPTIONS_MAP.detected]: false,
  [ACTION_OPTIONS_MAP.cleaned]: false,
  [ACTION_OPTIONS_MAP.quarantined]: false,
  [ACTION_OPTIONS_MAP.removed]: false,
  [ACTION_OPTIONS_MAP.allowed]: false,
  [ACTION_OPTIONS_MAP.blocked]: false,
  [ACTION_OPTIONS_MAP.clean_failed]: false,
  [ACTION_OPTIONS_MAP.quarantine_failed]: false,
  [ACTION_OPTIONS_MAP.remove_failed]: false,
  [ACTION_OPTIONS_MAP.allow_failed]: false,
  [ACTION_OPTIONS_MAP.abandoned]: false,
  [ACTION_OPTIONS_MAP.block_failed]: false,
};

export const ACTION_OPTIONS_I18N_KEY_MAP = {
  [ACTION_OPTIONS_MAP.unknown]: 'dashboard:unknown',
  [ACTION_OPTIONS_MAP.detected]: 'dashboard:detected',
  [ACTION_OPTIONS_MAP.cleaned]: 'dashboard:cleaned',
  [ACTION_OPTIONS_MAP.quarantined]: 'dashboard:quarantined',
  [ACTION_OPTIONS_MAP.removed]: 'dashboard:removed',
  [ACTION_OPTIONS_MAP.allowed]: 'dashboard:allowed',
  [ACTION_OPTIONS_MAP.blocked]: 'dashboard:blocked',
  [ACTION_OPTIONS_MAP.clean_failed]: 'dashboard:cleanFailed',
  [ACTION_OPTIONS_MAP.quarantine_failed]: 'dashboard:quarantineFailed',
  [ACTION_OPTIONS_MAP.remove_failed]: 'dashboard:removeFailed',
  [ACTION_OPTIONS_MAP.allow_failed]: 'dashboard:allowFailed',
  [ACTION_OPTIONS_MAP.abandoned]: 'dashboard:abandoned',
  [ACTION_OPTIONS_MAP.block_failed]: 'dashboard:blockFailed',
} as const;

export const ALL_GROUP_ID = 'all';
export const DEFAULT_GROUP_ID = '0';

/** Assign from Scheduled Access, External Share */
export const FROM_OTHER_GROUP_ID = 'other';

export const columnIdMap = {
  threat_name: 'name',
  computer_name: 'server_name',
  group_name: 'group_name',
  antivirus_software: 'antivirus',
  found_time: 'found_time',
  threat_file: 'file',
  threat_action: 'action',
  threat_status: 'status',
} as const;

type WidthSettings = Record<string, number>;

export const columnWidthMap = {
  [columnIdMap.threat_name]: 160,
  [columnIdMap.computer_name]: 160,
  [columnIdMap.group_name]: (lang: string) => {
    const widthSettings: WidthSettings = {
      en: 162,
      fr: 138,
    };
    return widthSettings[lang] || widthSettings.en;
  },
  [columnIdMap.antivirus_software]: 192,
  [columnIdMap.found_time]: (lang: string) => {
    const widthSettings: WidthSettings = {
      en: 162,
      de: 174,
      es: 196,
      it: 182,
      pt: 228,
      ko: 168,
    };
    return widthSettings[lang] || widthSettings.en;
  },
  [columnIdMap.threat_file]: 320,
  [columnIdMap.threat_action]: 122,
  [columnIdMap.threat_status]: 120,
} as const;
