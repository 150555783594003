import { useQuery } from '@tanstack/react-query';

import { featureControl } from '@/feature/toggle';
import { useTeamRole, useUnattendedTeamInformation } from '@/models/TeamInformation';
import { TeamKind } from '@/services/common/types';
import { infoQueryService } from '@/services/users';

export const useEmmPolicyAccess = () => {
  const unattendedTeamInformation = useUnattendedTeamInformation();
  const teamRole = useTeamRole();

  const infoQuery = useQuery({
    queryKey: ['info-query', 'emm-policy-access'],
    queryFn: () =>
      infoQueryService.execute({
        team_member_info: ['role', 'group_scope'],
      }),
    enabled: featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product'),
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 5 * 60 * 1000, // 5 minutes
    select: (data) => {
      const accessibleTeam = Object.entries(data).find(([_teamKind, { team_member_info }]) => {
        if (!team_member_info) {
          return false;
        }
        const { role, group_scope } = team_member_info;
        const isAdmin = role === 'manager' && group_scope === 'all';
        return role === 'owner' || isAdmin;
      });
      return Boolean(accessibleTeam);
    },
  });

  const availableTeamKinds: Array<TeamKind> = featureControl.getToggle('PCP_2681__Policy__unified__access_endpoint_policy_page')
    ? ['sba', 'srs', 'msp', 'splashtop', 'ste_custom']
    : ['sba', 'srs', 'msp'];

  const isTeamSupported = unattendedTeamInformation && availableTeamKinds.includes(unattendedTeamInformation.teamKind);
  const isRoleSupported = teamRole.isOwner || teamRole.isAdmin;

  return {
    canAccess_deprecated: featureControl.getToggle('PCP_1134__Policy_management') && Boolean(isTeamSupported && isRoleSupported),
    canAccessQuery: infoQuery,
  };
};
