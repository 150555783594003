import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useRippleFlashMessage } from '@/design';
import { featureControl } from '@/feature/toggle';
import { useTeamInfoForChannelsQuery, useTeamInfoForChannels_deprecated } from '@/modules/ServiceDesk/Channels/useTeamInfoForChannels';
import { SurveyListError, surveyListErrorSchema } from '@/services/serviceDesk/surveys';

import { getSurveyList } from './service';

type SurveyListQueryProps = {
  errorMessages?: Record<SurveyListError['result'], React.ReactNode>;
};

export const useSurveyListQuery = ({ errorMessages }: SurveyListQueryProps = {}) => {
  const teamInfoForChannels_deprecated = useTeamInfoForChannels_deprecated();
  const teamInfoForChannelsQuery = useTeamInfoForChannelsQuery();

  const teamId = featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product')
    ? teamInfoForChannelsQuery.data?.teamId
    : teamInfoForChannels_deprecated.teamId;
  const { flashMessage } = useRippleFlashMessage();

  const query = useQuery({
    queryKey: ['survey', 'list', teamId],
    queryFn: async () => {
      if (typeof teamId === 'undefined') {
        throw new Error('Dependency not passed to query');
      }
      const result = await getSurveyList(teamId);
      return result.surveys;
    },
    enabled: typeof teamId !== 'undefined',
  });

  useEffect(function handleError() {
    if (!errorMessages || !query.isError) {
      return;
    }
    const errorValidation = surveyListErrorSchema.safeParse(query.error);
    if (!errorValidation.success) {
      console.error('Invalid error code', errorValidation.error);
      return;
    }
    const { result } = errorValidation.data;
    if (!errorMessages[result]) {
      console.error('Unhandled error code: ', result);
      return;
    }
    flashMessage({ variant: 'error', title: errorMessages[result] });
  });

  return query;
};
