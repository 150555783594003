import { useQuery } from '@tanstack/react-query';

import { featureControl } from '@/feature/toggle';
import { TeamKind } from '@/services/common/types';
import { infoQueryService } from '@/services/users';

type SosCustomizationAccess =
  | { teamType: TeamKind; isExpired: boolean; canAccess: true }
  | { teamType: ''; isExpired: false; canAccess: false };

export const useSosCustomizationAccessQuery = () => {
  return useQuery({
    queryKey: ['info-query', 'sos-customization-access'],
    queryFn: () =>
      infoQueryService.execute({
        team_member_permissions: ['technician_manager'],
        team_info: ['is_expired'],
      }),
    enabled: featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product'),
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 5 * 60 * 1000, // 5 minutes
    select: (data): SosCustomizationAccess => {
      return Object.entries(data).reduce(
        (result, [teamType, { team_member_permissions, team_info }]) => {
          if (team_member_permissions.technician_manager) {
            result = {
              teamType: teamType as TeamKind,
              isExpired: team_info.is_expired,
              canAccess: team_member_permissions.technician_manager,
            };
          }
          return result;
        },
        { teamType: '', isExpired: false, canAccess: false } as SosCustomizationAccess,
      );
    },
  });
};
