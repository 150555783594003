import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import { useTeamId } from '@/models/TeamInformation';
import { ALL_GROUP_ID, DEFAULT_GROUP_ID } from '@/modules/AntivirusSessions/constants';
import { sortGroup } from '@/modules/AntivirusSessions/utils';
import { getComputerGroupList } from '@/services/group';

export const useGroupList = () => {
  const { t } = useTranslation();
  const teamId = useTeamId();
  const groupList = useQuery({
    queryKey: ['groupList', 'antivirus threat management', teamId],
    queryFn: () => getComputerGroupList(teamId),
    select: (data) => data.map(({ id, name }) => ({ id: String(id), name })).sort(sortGroup),
    cacheTime: Infinity,
  });

  const allGroup = { id: ALL_GROUP_ID, name: t('computer:allGroups') };
  const defaultGroup = { id: DEFAULT_GROUP_ID, name: t('computer:defaultGroup') };

  return (groupList?.data || []).concat([allGroup, defaultGroup]);
};
