import { useTeamRole } from '@/models/TeamInformation';
import { DEFAULT_GROUP_ID } from '@/modules/AntivirusSessions/constants';

import { useGroupList } from './useGroupList';

// Member can not access the antivirus sessions page
export const useGroupListWithPermission = () => {
  const { isGroupAdmin } = useTeamRole();

  const groupList = useGroupList();

  if (isGroupAdmin) {
    return groupList.filter((group) => group.id !== DEFAULT_GROUP_ID);
  }

  return groupList;
};
