import { useQuery } from '@tanstack/react-query';

import { featureControl } from '@/feature/toggle';
import { infoQueryService } from '@/services/users';

export const useSystemToolAccessQuery = () => {
  return useQuery({
    queryKey: ['info-query', 'system-tools'],
    queryFn: () =>
      infoQueryService.execute({
        team_member_permissions: ['premium_tool', 'sos_service_desk_premium_tool'],
      }),
    enabled: featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product'),
    retry: false,
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
    select: (data) => {
      const isPremiumToolAccessible = Object.values(data).some((team) => team.team_member_permissions?.premium_tool);
      const isSosServiceDeskPremiumToolAccessible = Object.values(data).some(
        (team) => team.team_member_permissions?.sos_service_desk_premium_tool,
      );

      const canAccess = isPremiumToolAccessible || isSosServiceDeskPremiumToolAccessible;

      return {
        canAccess,
      };
    },
  });
};
