import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { shouldShowPreferencePolicy, shouldShowPreferencePolicy_new } from '@/components/Navigation/routeConfig/utils';
import { featureControl } from '@/feature/toggle';
import { useEmmPolicyAccess } from '@/hooks';
import { useTeamRole } from '@/models/TeamInformation';
import { useTeamInformation } from '@/models/TeamInformation';
import { getComputerListColumnPermissions } from '@/services/computers';
import type { ComputerListColumnPermissions } from '@/services/computers';
import { type TeamKindResult, infoQueryService } from '@/services/users';
import useTeamInformation_deprecated from '@/utils/useTeamInformation';
import type { TeamInformation } from '@/utils/useTeamInformation';

import type { ColumnPermissions } from '../ComputerList';
import { canSeeGroup, canSeeGroup_new, canSeeNotes, canSeeNotes_new } from '../ComputerList';

export const useColumnPermissions = featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product')
  ? useColumnPermissions_new
  : useColumnPermissions_deprecated;

function useColumnPermissions_deprecated(): ColumnPermissions | null {
  const teamInformation = useTeamInformation_deprecated();

  const permissionQuery = useQuery({
    queryKey: ['allComputerList', 'getColumnPermissions'],
    queryFn: () => getComputerListColumnPermissions(),
    staleTime: Infinity,
  });

  const permissionMap = permissionQuery.data;

  const hasSeeGroupPermission = canSeeGroup(teamInformation, permissionMap);
  const hasSeePreferencePolicyPermission = featureControl.getToggle('PCP_1134__Policy_management')
    ? shouldShowPreferencePolicy(teamInformation) && Boolean(permissionMap?.team_permissions.preference_policy)
    : shouldShowPreferencePolicy(teamInformation);
  const hasSeeAlertCountPermission = canShowAlertCount(teamInformation);
  const hasSeeWindowsUpdatesPermission = canShowWindowsUpdates(teamInformation);
  const hasSeeAntivirusStatusPermission = canShowAntivirusStatus(teamInformation, permissionMap);
  const hasSeeSecurityLayerPermission = canShowSecurityLayer(teamInformation, permissionMap);
  const hasSeeNotesPermission = canSeeNotes(teamInformation, permissionMap);
  const hasWebAppPermission = Boolean(permissionMap?.team_permissions.web_src);
  const hasRemoteRebootPermission = Boolean(permissionMap?.team_permissions.remote_reboot);
  const hasMemberRebootComputerPermission = Boolean(permissionMap?.team_permissions.member_reboot_computer);
  const { canAccessQuery, canAccess_deprecated } = useEmmPolicyAccess();
  const hasSeeEmmPolicyPermission = featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product')
    ? canAccessQuery.isFetched && Boolean(canAccessQuery.data)
    : canAccess_deprecated;

  const columnPermission = useMemo<ColumnPermissions>(
    () => ({
      alertCount: hasSeeAlertCountPermission,
      antivirusStatus: hasSeeAntivirusStatusPermission,
      emmPolicy: hasSeeEmmPolicyPermission,
      group: hasSeeGroupPermission,
      memberRebootComputer: hasMemberRebootComputerPermission,
      notes: hasSeeNotesPermission,
      preferencePolicy: hasSeePreferencePolicyPermission,
      remoteReboot: hasRemoteRebootPermission,
      securityLayer: hasSeeSecurityLayerPermission,
      webApp: hasWebAppPermission,
      windowsUpdates: hasSeeWindowsUpdatesPermission,
    }),
    [
      hasMemberRebootComputerPermission,
      hasRemoteRebootPermission,
      hasSeeAlertCountPermission,
      hasSeeAntivirusStatusPermission,
      hasSeeEmmPolicyPermission,
      hasSeeGroupPermission,
      hasSeeNotesPermission,
      hasSeePreferencePolicyPermission,
      hasSeeSecurityLayerPermission,
      hasSeeWindowsUpdatesPermission,
      hasWebAppPermission,
    ],
  );

  if (permissionQuery.isFetching || typeof permissionMap === 'undefined') return null;

  return columnPermission;
}

function useColumnPermissions_new(): ColumnPermissions | null {
  const { teamKind } = useTeamInformation();
  const { isManager } = useTeamRole();

  const permissionQuery = useQuery({
    queryKey: ['allComputerList', 'getColumnPermissions'],
    queryFn: async () => {
      const params = {
        team_permissions: [
          'display_group_to_member',
          'member_fetch_computer_notes',
          'manage_infra_gen',
          'web_src',
          'remote_reboot',
          'member_reboot_computer',
          'preference_policy',
        ],
        team_member_permissions: ['alert', 'os_opportunities', 'antivirus', 'endpoint_security'],
      } as const;

      const result = await infoQueryService.execute(params);

      const [, unattendedTeamKindData] =
        Object.entries<TeamKindResult<typeof params>>(result).find(([teamKind]) => teamKind !== 'sos') ?? [];

      if (unattendedTeamKindData) return unattendedTeamKindData;

      return Promise.reject(new Error('No data found'));
    },
    staleTime: Infinity,
  });

  const permissionMap = permissionQuery.data;

  const hasSeeGroupPermission = canSeeGroup_new(isManager, permissionMap);
  const hasSeePreferencePolicyPermission = featureControl.getToggle('PCP_1134__Policy_management')
    ? shouldShowPreferencePolicy_new(teamKind, isManager) && Boolean(permissionMap?.team_permissions?.preference_policy)
    : shouldShowPreferencePolicy_new(teamKind, isManager);
  const hasSeeAlertCountPermission = Boolean(permissionMap?.team_member_permissions?.alert);
  const hasSeeWindowsUpdatesPermission = Boolean(permissionMap?.team_member_permissions?.os_opportunities);
  const hasSeeAntivirusStatusPermission = Boolean(
    permissionMap?.team_member_permissions?.antivirus || permissionMap?.team_member_permissions?.endpoint_security,
  );
  const hasSeeSecurityLayerPermission = canShowSecurityLayer_new(isManager, permissionMap);
  const hasSeeNotesPermission = canSeeNotes_new(isManager, permissionMap);
  const hasWebAppPermission = Boolean(permissionMap?.team_permissions?.web_src);
  const hasRemoteRebootPermission = Boolean(permissionMap?.team_permissions?.remote_reboot);
  const hasMemberRebootComputerPermission = Boolean(permissionMap?.team_permissions?.member_reboot_computer);
  const { canAccessQuery } = useEmmPolicyAccess();
  const hasSeeEmmPolicyPermission = Boolean(canAccessQuery.data);

  const columnPermission = useMemo<ColumnPermissions>(
    () => ({
      alertCount: hasSeeAlertCountPermission,
      antivirusStatus: hasSeeAntivirusStatusPermission,
      emmPolicy: hasSeeEmmPolicyPermission,
      group: hasSeeGroupPermission,
      memberRebootComputer: hasMemberRebootComputerPermission,
      notes: hasSeeNotesPermission,
      preferencePolicy: hasSeePreferencePolicyPermission,
      remoteReboot: hasRemoteRebootPermission,
      securityLayer: hasSeeSecurityLayerPermission,
      webApp: hasWebAppPermission,
      windowsUpdates: hasSeeWindowsUpdatesPermission,
    }),
    [
      hasMemberRebootComputerPermission,
      hasRemoteRebootPermission,
      hasSeeAlertCountPermission,
      hasSeeAntivirusStatusPermission,
      hasSeeEmmPolicyPermission,
      hasSeeGroupPermission,
      hasSeeNotesPermission,
      hasSeePreferencePolicyPermission,
      hasSeeSecurityLayerPermission,
      hasSeeWindowsUpdatesPermission,
      hasWebAppPermission,
    ],
  );

  if (!permissionQuery.isFetched || !canAccessQuery.isFetched || typeof permissionMap === 'undefined') return null;

  return columnPermission;
}

/**
 * Only owner and admin (NOT Group Admin) can see alert count
 */
function canShowAlertCount(teamInformation: TeamInformation | null): boolean {
  return Boolean(
    teamInformation?.teamPermissions.premierPack &&
      (teamInformation?.role === 'owner' || (teamInformation?.role === 'manager' && teamInformation.groupScope === 'all')),
  );
}

function canShowWindowsUpdates(teamInformation: TeamInformation | null): boolean {
  return Boolean(
    teamInformation?.teamPermissions.premierPack && (teamInformation?.role === 'owner' || teamInformation?.role === 'manager'),
  );
}

function canShowAntivirusStatus(
  teamInformation: TeamInformation | null,
  permissionMap: ComputerListColumnPermissions | undefined,
): boolean {
  return Boolean(permissionMap?.team_permissions?.antivirus && (teamInformation?.role === 'owner' || teamInformation?.role === 'manager'));
}

/**
 * @deprecated use `canShowSecurityLayer_new` instead
 */
function canShowSecurityLayer(teamInformation: TeamInformation | null, permissionMap: ComputerListColumnPermissions | undefined): boolean {
  return Boolean(
    permissionMap?.team_permissions?.manage_infra_gen && (teamInformation?.role === 'owner' || teamInformation?.role === 'manager'),
  );
}

/**
 * @param isManager {boolean} Value from `useTeamRole`
 * @param permissionMap {ComputerListColumnPermissions | undefined}
 */
function canShowSecurityLayer_new(isManager: boolean, permissionMap: ComputerListColumnPermissions | undefined): boolean {
  return Boolean(isManager && permissionMap?.team_permissions?.manage_infra_gen);
}
