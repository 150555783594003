import { useCallback, useMemo } from 'react';

import { useSetAtom } from 'jotai';

import { useFeatureState } from './useFeatureState';
import { useNormalGranularControlQuery } from './useNormalGranularControlQuery';
import { useTeamControlContext } from './useTeamControlContext';
import { useTeamMetadata } from './useTeamMetadata';

/**
 * Refresh all normal granular control.
 *
 * The callback will trigger a loading spinner while fetching the granular control. (from `useNormalGranularControlQuery`)
 */
export function useRefreshAllNormalGranularControl() {
  const { team_id } = useTeamMetadata();

  const featureState = useFeatureState('granular_control');
  const features = useMemo(() => featureState?.available_features ?? [], [featureState]);

  const { atoms } = useTeamControlContext();
  const dispatch = useSetAtom(atoms.granularControlAtom);

  const normalGranularControlQuery = useNormalGranularControlQuery(team_id, features, {
    enabled: false,
  });

  return useCallback(() => {
    normalGranularControlQuery.refetch().then((result) => {
      if (result.isSuccess) {
        const normalGranularControl = result.data;

        dispatch({
          type: 'overwrite',
          updateDraft: (draft) => {
            features.forEach((feature) => {
              if (draft[feature]?.normal) {
                draft[feature].normal = {
                  owner: 'on',
                  manager: 'off',
                  member: 'off',
                  ...normalGranularControl[feature],
                };
              } else {
                draft[feature] = {
                  normal: {
                    owner: 'on',
                    manager: 'off',
                    member: 'off',
                    ...normalGranularControl[feature],
                  },
                  grant: {
                    manager: 'off',
                  },
                };
              }
            });
          },
        });
      }
    });
  }, [dispatch, features, normalGranularControlQuery]);
}
