import { Trans, useTranslation } from 'next-i18next';

import { singleSpace } from '@/constants';
import { RippleTypography } from '@/design';
import { featureControl } from '@/feature/toggle';

type SelectionCountProps = {
  selectedCount: number;
};

const SelectionCount = ({ selectedCount }: SelectionCountProps) => {
  const { t } = useTranslation();

  return (
    <RippleTypography variant="body02">
      {featureControl.getToggle('PCP_460__User_Management') ? (
        <Trans
          t={t}
          i18nKey="computer:computerSelected"
          values={{
            count: selectedCount,
          }}
          components={{
            Strong: <span data-testid="selection-count" />,
          }}
        />
      ) : (
        <>
          <span data-testid="selection-count">{selectedCount}</span>
          {singleSpace}
          {t('computer:selector.selected')}
        </>
      )}
    </RippleTypography>
  );
};

export default SelectionCount;
