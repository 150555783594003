import { featureControl } from '@/feature/toggle';

const connectPermission = ['conn_perm'] as const;

const relayRecording = [
  'relay_recording',
  'sos_relay_recording',
  ...(featureControl.getToggle('PCP_506__TeamSettings_RoleManagement')
    ? (['relay_recording_access', 'sos_relay_recording_access'] as const)
    : []),
] as const;

/**
 * ref: be-app app/models/concerns/model_configs/granular.rb CONTROLS_BY_FEATURE_CAPACITY
 */
export const granularControlKeys = [
  'attended_access',
  'file',
  'remote_print',
  'copy_paste',
  'required_2sv',
  'one_to_many',
  'command_prompt',
  'watermark',
  'remote_control',
  ...connectPermission,
  ...relayRecording,
  ...(featureControl.getToggle('PCP_506__TeamSettings_RoleManagement')
    ? ([
        'emm_patch_opportunities',
        'os_opportunities',
        'alert',
        'event_logs',
        'off_session_file',
        'file_transfer',
        'premium_tool',
        'antivirus',
        'endpoint_security',
        'inventory',
        'smart_action',
        'one_to_many_basic',
      ] as const)
    : []),
] as const;

export const granularControlDetailSettingKeys = ['conn_perm_setting'] as const;
