import { featureControl } from '@/feature/toggle';
import { ATTENDED_TEAM_KIND, TeamInformation } from '@/models/TeamInformation';
import { GroupScope, MemberStatus, Role } from '@/services/users/infoQueryService';

/**
 * @deprecated use {@link isGroupAdmin} instead
 * TODO: Remove this function along with feature toggle: PCP_2760_modify_permission_checking_for_new_unified_product
 */
const isGroupAdmin_deprecated = (teamInformation: TeamInformation | null) => {
  return teamInformation?.role === 'manager' && teamInformation?.group_scope === 'part';
};

export const isGroupAdmin = ({ role, groupScope }: { role: Role; groupScope: GroupScope }) => {
  return role === 'manager' && groupScope === 'part';
};

/**
 * @deprecated use {@link shouldShowServiceDesk} instead
 * TODO: Remove this function along with feature toggle: PCP_2760_modify_permission_checking_for_new_unified_product
 */
export function shouldShowServiceDeskChannelBySOS_deprecated(sosTeamInformation: TeamInformation | null) {
  if (!sosTeamInformation) return false;
  if (sosTeamInformation.teamKind !== ATTENDED_TEAM_KIND) return false;
  if (isGroupAdmin_deprecated(sosTeamInformation)) return false;

  return (
    ['enabled', 'disabled'].includes(sosTeamInformation.member_status) &&
    sosTeamInformation?.team_permissions.sos_service_desk &&
    sosTeamInformation?.team_member_permissions.attended_access &&
    sosTeamInformation?.team_member_permissions.technician_manager
  );
}

/**
 * @deprecated use {@link shouldShowServiceDesk} instead
 * TODO: Remove this function along with feature toggle: PCP_2760_modify_permission_checking_for_new_unified_product
 */
export function shouldShowServiceDeskChannelByEnterprise_deprecated(teamInformation: TeamInformation | null) {
  if (!teamInformation) return false;
  if (
    !(
      teamInformation.teamKind === 'srs' &&
      [
        'enterprise',
        'ste_lite_sos_basic',
        'ste_lite_sos_plus',
        ...(featureControl.getToggle('PCP_2061__TeamSettings__SOS_Unlimited_team_computer_quota_hardcode_adjustment')
          ? ['ste_lite_sos_unlimited']
          : []),
      ].includes(teamInformation.plan)
    )
  )
    return false;
  if (isGroupAdmin_deprecated(teamInformation)) return false;

  return (
    ['enabled', 'disabled'].includes(teamInformation.member_status) &&
    teamInformation?.team_permissions.sos_service_desk &&
    teamInformation?.team_member_permissions.attended_access &&
    teamInformation?.team_member_permissions.technician_manager
  );
}

/** Determine whether the user can access the channels of service desk */
export function shouldShowServiceDeskChannel({
  role,
  groupScope,
  memberStatus,
  sosServiceDesk,
  attendedAccess,
  technicianManager,
}: {
  role: Role;
  groupScope: GroupScope;
  memberStatus: MemberStatus;
  /** the value sos_service_desk of team permission from the info-query API */
  sosServiceDesk: boolean;
  /** the value attended_access of team member permission from the info-query API */
  attendedAccess: boolean;
  /** the value technician_manager of team member permission from the info-query API */
  technicianManager: boolean;
}) {
  return (
    !isGroupAdmin({ role, groupScope }) &&
    ['enabled', 'disabled'].includes(memberStatus) &&
    sosServiceDesk &&
    attendedAccess &&
    technicianManager
  );
}

/**
 * @deprecated use {@link shouldShowServiceDeskSupportSession} instead
 * TODO: Remove this function along with feature toggle: PCP_2760_modify_permission_checking_for_new_unified_product
 */
export function shouldShowServiceDeskSupportSessionBySOS_deprecated(sosTeamInformation: TeamInformation | null) {
  if (!sosTeamInformation) return false;
  if (sosTeamInformation.teamKind !== ATTENDED_TEAM_KIND) return false;

  return (
    sosTeamInformation.member_status === 'enabled' &&
    sosTeamInformation?.team_permissions.sos_service_desk &&
    sosTeamInformation?.team_member_permissions.attended_access
  );
}

/**
 * @deprecated use {@link shouldShowServiceDeskSupportSession} instead
 * TODO: Remove this function along with feature toggle: PCP_2760_modify_permission_checking_for_new_unified_product
 */
export function shouldShowServiceDeskSupportSessionByEnterprise_deprecated(teamInformation: TeamInformation | null) {
  if (!teamInformation) return false;
  if (
    !(
      teamInformation.teamKind === 'srs' &&
      [
        'enterprise',
        'ste_lite_sos_basic',
        'ste_lite_sos_plus',
        ...(featureControl.getToggle('PCP_2061__TeamSettings__SOS_Unlimited_team_computer_quota_hardcode_adjustment')
          ? ['ste_lite_sos_unlimited']
          : []),
      ].includes(teamInformation.plan)
    )
  )
    return false;

  return (
    teamInformation.member_status === 'enabled' &&
    teamInformation?.team_permissions.sos_service_desk &&
    teamInformation?.team_member_permissions.attended_access
  );
}

/** Determine whether the user can access the support session of service desk */
export function shouldShowServiceDeskSupportSession({
  memberStatus,
  sosServiceDesk,
  attendedAccess,
}: {
  memberStatus: MemberStatus;
  /** the value sos_service_desk of team permission from the info-query API */
  sosServiceDesk: boolean;
  /** the value attended_access of team member permission from the info-query API */
  attendedAccess: boolean;
}) {
  return memberStatus === 'enabled' && sosServiceDesk && attendedAccess;
}
