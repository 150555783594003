import { useQuery } from '@tanstack/react-query';

import { featureControl } from '@/feature/toggle';
import { useAttendedTeamInformation, useUnattendedTeamInformation } from '@/models/TeamInformation';
import { useUserProfile } from '@/models/UserProfile';
import {
  isGroupAdmin,
  shouldShowServiceDeskSupportSession,
  shouldShowServiceDeskSupportSessionByEnterprise_deprecated,
  shouldShowServiceDeskSupportSessionBySOS_deprecated,
} from '@/modules/ServiceDesk';
import { infoQueryService } from '@/services/users';
import { compareToCurrentDate } from '@/utils/useTeamInformation';

import type { TeamInfo } from './types';

/**
 * @deprecated use {@link useTeamInfoForSupportSession} instead
 * TODO: Remove this function along with feature toggle PCP_2760_modify_permission_checking_for_new_unified_product
 */
export function useTeamInfoForSupportSession_deprecated(): TeamInfo {
  const attendedTeamInformation = useAttendedTeamInformation();
  const unattendedTeamInformation = useUnattendedTeamInformation();
  const profile = useUserProfile();

  if (attendedTeamInformation && shouldShowServiceDeskSupportSessionBySOS_deprecated(attendedTeamInformation)) {
    const {
      team_id: teamId,
      member_id: memberId,
      expires_at,
      role,
      group_scope: groupScope,
      team_member_permissions: { technician_manager },
    } = attendedTeamInformation;
    return {
      teamType: 'sos',
      teamId,
      memberId,
      timeZoneMinutes: profile.time_zone_minutes,
      technicianManager: technician_manager,
      isExpired: compareToCurrentDate(expires_at),
      isGroupAdmin: role !== 'member' && groupScope === 'part',
      email: profile.email,
    };
  }

  if (unattendedTeamInformation && shouldShowServiceDeskSupportSessionByEnterprise_deprecated(unattendedTeamInformation)) {
    const {
      team_id: teamId,
      member_id: memberId,
      expires_at,
      role,
      group_scope: groupScope,
      team_member_permissions: { technician_manager },
    } = unattendedTeamInformation;
    return {
      teamType: 'srs',
      teamId,
      memberId,
      timeZoneMinutes: profile.time_zone_minutes,
      technicianManager: technician_manager,
      isExpired: compareToCurrentDate(expires_at),
      isGroupAdmin: role !== 'member' && groupScope === 'part',
      email: profile.email,
    };
  }

  return {
    teamType: '',
    teamId: -1,
    memberId: -1,
    timeZoneMinutes: 0,
    technicianManager: false,
    isExpired: true,
    isGroupAdmin: false,
    email: '',
  };
}

export function useTeamInfoForSupportSessionQuery() {
  const attendedTeamInformation = useAttendedTeamInformation();
  const unattendedTeamInformation = useUnattendedTeamInformation();
  const profile = useUserProfile();
  return useQuery({
    queryKey: ['info-query', 'support-session'],
    queryFn: async () =>
      infoQueryService.execute({
        team_info: ['is_expired'],
        team_permissions: ['sos_service_desk'],
        team_member_info: ['role', 'group_scope', 'member_status'],
        team_member_permissions: ['team_settings_management', 'attended_access', 'technician_manager'],
      }),
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 5 * 60 * 1000, // 5 minutes
    enabled: featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product'),
    select: (data): TeamInfo => {
      // Handle the abnormal case that if a user has duplicated service desk permission from both sos and srs, we should use sos first
      if (
        data.sos &&
        /** TODO: Remove attend team infomation when data is ready in info-query */
        attendedTeamInformation &&
        shouldShowServiceDeskSupportSession({
          memberStatus: data.sos.team_member_info.member_status,
          sosServiceDesk: data.sos.team_permissions.sos_service_desk,
          attendedAccess: data.sos.team_member_permissions.attended_access,
        })
      ) {
        const { team_id, member_id } = attendedTeamInformation;
        return {
          teamType: 'sos',
          teamId: team_id,
          memberId: member_id,
          timeZoneMinutes: profile.time_zone_minutes,
          technicianManager: data.sos.team_member_permissions.technician_manager,
          isExpired: data.sos.team_info.is_expired,
          isGroupAdmin: isGroupAdmin({ role: data.sos.team_member_info.role, groupScope: data.sos.team_member_info.group_scope }),
          email: profile.email,
        };
      }
      // Handle the rest case
      return Object.entries(data).reduce(
        (result, [teamType, { team_info, team_member_permissions, team_member_info, team_permissions }]) => {
          if (
            /** TODO: Remove unattended team infomation when the data is ready in info-query */
            unattendedTeamInformation &&
            shouldShowServiceDeskSupportSession({
              memberStatus: team_member_info.member_status,
              sosServiceDesk: team_permissions.sos_service_desk,
              attendedAccess: team_member_permissions.attended_access,
            })
          ) {
            const { team_id, member_id } = unattendedTeamInformation;
            return {
              teamType: teamType,
              teamId: team_id,
              memberId: member_id,
              timeZoneMinutes: profile.time_zone_minutes,
              technicianManager: team_member_permissions.technician_manager,
              isExpired: team_info.is_expired,
              isGroupAdmin: isGroupAdmin({ role: team_member_info.role, groupScope: team_member_info.group_scope }),
              email: profile.email,
            };
          }
          return result;
        },
        {
          teamType: '',
          teamId: -1,
          memberId: -1,
          timeZoneMinutes: 0,
          technicianManager: false,
          isExpired: true,
          isGroupAdmin: false,
          email: '',
        } as TeamInfo,
      );
    },
  });
}
