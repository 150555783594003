import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { ChangeEvent, UIEvent } from 'react';

import { Box, Divider, Flex, forwardRef } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { Virtuoso } from 'react-virtuoso';

import {
  RippleAlertSpecificColor,
  RippleBodyText02,
  RippleButton,
  RippleClose16,
  RippleIconButton,
  RippleInput,
  RippleOK,
  RippleSearch,
  RippleTypography,
} from '@/design';
import type { RippleButtonProps, RippleComputerGroupDropdownData } from '@/design';
import { ALL_GROUP_ID, DEFAULT_GROUP_ID, FROM_OTHER_GROUP_ID } from '@/modules/AntivirusSessions/constants';

type AssignGroupMenuItemProps = RippleButtonProps & { isSelected: boolean };

type ComputerGroupMenuProps = {
  showAllGroup: boolean;
  showDefaultGroup: boolean;
  showFromOtherGroup: boolean;
  groups: Array<RippleComputerGroupDropdownData>;
  onSelect: (selectedGroup: RippleComputerGroupDropdownData) => void;
  menuHeight?: number;
} & ({ selectMode: true; selectedGroup: RippleComputerGroupDropdownData } | { selectMode?: false; selectedGroup?: never });

type AdditionalGroupHashMap = {
  allGroup?: RippleComputerGroupDropdownData;
  defaultGroup?: RippleComputerGroupDropdownData;
  fromOtherGroup?: RippleComputerGroupDropdownData;
};

export function ComputerGroupMenu(props: ComputerGroupMenuProps): React.JSX.Element {
  const { t } = useTranslation();

  const { showAllGroup, showDefaultGroup, showFromOtherGroup, groups, onSelect, menuHeight = 400 } = props;

  const sortedGroups = useMemo(() => {
    const filteredGroups = groups.filter((group) => group.id !== DEFAULT_GROUP_ID && group.id !== FROM_OTHER_GROUP_ID);

    return filteredGroups.sort((aGroup, bGroup) => {
      if (aGroup.name.toLocaleUpperCase() > bGroup.name.toLocaleUpperCase()) {
        return 1;
      } else {
        return -1;
      }
    });
  }, [groups]);

  const allAdditionalGroupHashMap: Required<AdditionalGroupHashMap> = useMemo(() => {
    return {
      allGroup: {
        id: ALL_GROUP_ID,
        name: t('computer:allGroups'),
      },
      defaultGroup: {
        id: DEFAULT_GROUP_ID,
        name: t('computer:defaultGroup'),
      },
      fromOtherGroup: {
        id: FROM_OTHER_GROUP_ID,
        name: t('computer:selector.fromOtherGroup'),
      },
    };
  }, [t]);

  const [inputValue, setInputValue] = useState<string>('');
  const [filteredGroups, setFilteredGroups] = useState(sortedGroups);

  const filteredAdditionalGroupHashMap = useMemo(() => {
    const additional: AdditionalGroupHashMap = {};

    const filterValue = inputValue?.trim()?.toLocaleUpperCase() ?? '';

    if (showAllGroup && (!filterValue || isContainFilterValue(filterValue, allAdditionalGroupHashMap.allGroup.name.toLocaleUpperCase()))) {
      additional.allGroup = allAdditionalGroupHashMap.allGroup;
    }
    if (
      showDefaultGroup &&
      (!filterValue || isContainFilterValue(filterValue, allAdditionalGroupHashMap.defaultGroup.name.toLocaleUpperCase()))
    ) {
      additional.defaultGroup = allAdditionalGroupHashMap.defaultGroup;
    }
    if (
      showFromOtherGroup &&
      (!filterValue || isContainFilterValue(filterValue, allAdditionalGroupHashMap.fromOtherGroup.name.toLocaleUpperCase()))
    ) {
      additional.fromOtherGroup = allAdditionalGroupHashMap.fromOtherGroup;
    }

    return additional;
  }, [allAdditionalGroupHashMap, showAllGroup, showDefaultGroup, showFromOtherGroup, inputValue]);

  useEffect(() => {
    if (inputValue === '') {
      setFilteredGroups(sortedGroups);
    } else {
      const filterValue = inputValue.trim().toLocaleUpperCase();
      const nextFilteredGroups = sortedGroups.filter((group) => isContainFilterValue(filterValue, group.name));

      setFilteredGroups(nextFilteredGroups);
    }
  }, [setInputValue, sortedGroups, inputValue]);

  const isNoAdditional = Object.keys(filteredAdditionalGroupHashMap).length === 0;
  const isNoFilteredGroups = filteredGroups.length === 0;
  const isNoResult = isNoFilteredGroups && isNoAdditional;

  const makeHandleSelect = (nextSelectGroup: RippleComputerGroupDropdownData) => {
    return () => {
      setInputValue('');
      onSelect(nextSelectGroup);
    };
  };

  return (
    <ComputerGroupMenuContext.Provider
      value={{
        inputValue,
        setInputValue,
        onSelect,
        filteredGroups,
        allAdditionalGroupHashMap,
        filteredAdditionalGroupHashMap,
        isNoAdditional,
        isNoFilteredGroups,
        computeGroupSelected,
      }}
    >
      <Virtuoso
        style={{ height: menuHeight }}
        data={filteredGroups}
        components={{
          Header: MenuHeader,
          List: forwardRef(({ children, ...otherProps }, ref) => (
            <Flex ref={ref} flexDirection="column" justifyContent="stretch" {...otherProps}>
              {!inputValue && (
                <RippleTypography variant="heading09" color="neutral.100" px="12px" py="5px">
                  {t('computer:group')}
                </RippleTypography>
              )}
              {children}
              {isNoResult && (
                <Flex padding="4px 12px" alignItems="center">
                  <RippleAlertSpecificColor />
                  <RippleTypography as="span" variant="body02" ml="11px" color="dark.90">
                    {t('common:noResult')}
                  </RippleTypography>
                </Flex>
              )}
            </Flex>
          )),
        }}
        itemContent={(_index, inputGroup) => {
          const { id, name } = inputGroup;
          return (
            <AssignGroupMenuItem
              data-testid={`group-filtering-${id}`}
              key={id}
              w="100%"
              onClick={makeHandleSelect(inputGroup)}
              isSelected={computeGroupSelected(id)}
            >
              {name}
            </AssignGroupMenuItem>
          );
        }}
      />
    </ComputerGroupMenuContext.Provider>
  );

  function computeGroupSelected(groupId: RippleComputerGroupDropdownData['id']): boolean {
    return Boolean(props.selectMode && props.selectedGroup.id === groupId);
  }
}

function isContainFilterValue(upperFilterValue: string, groupName: string) {
  const upperGroupName = groupName.trim().toLocaleUpperCase();

  return upperGroupName.includes(upperFilterValue);
}
function MenuHeader(): React.JSX.Element {
  const {
    inputValue,
    setInputValue,
    onSelect,
    allAdditionalGroupHashMap,
    filteredAdditionalGroupHashMap,
    isNoAdditional,
    isNoFilteredGroups,
    computeGroupSelected,
  } = useContext(ComputerGroupMenuContext);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setInputValue(value);
    },
    [setInputValue],
  );

  const handleRemoveInput = useCallback(
    (event: UIEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setInputValue('');
    },
    [setInputValue],
  );

  const makeHandleSelect = (nextSelectGroup: RippleComputerGroupDropdownData) => {
    return () => {
      setInputValue('');
      onSelect(nextSelectGroup);
    };
  };

  return (
    <Flex flexDirection="column">
      <Box position="relative" padding="0 12px" mt="12px" mb="6px">
        <RippleInput
          height="32px"
          autoFocus
          pr="56px"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (!['ArrowUp', 'ArrowDown', 'Escape'].includes(e.key)) {
              e.stopPropagation();
            }
          }}
        />
        {inputValue && (
          <RippleIconButton
            aria-label="clear"
            data-testid="group-dropdown-remove-btn"
            zIndex={2}
            p="1px"
            minW="initial"
            width="18px"
            height="18px"
            position="absolute"
            top="50%"
            right="44px"
            transform="translateY(-50%)"
            borderRadius="50%"
            backgroundColor="neutral.80"
            _hover={{
              backgroundColor: 'neutral.300',
            }}
            onClick={handleRemoveInput}
            icon={<RippleClose16 color="#FFFFFF" />}
          />
        )}
        <Box zIndex={2} position="absolute" top="50%" right="20px" transform="translateY(-50%)">
          <RippleSearch />
        </Box>
      </Box>
      {filteredAdditionalGroupHashMap.allGroup && (
        <AssignGroupMenuItem
          data-testid="group-filtering-all-group"
          onClick={makeHandleSelect(allAdditionalGroupHashMap.allGroup)}
          isSelected={computeGroupSelected(allAdditionalGroupHashMap.allGroup.id)}
        >
          {allAdditionalGroupHashMap.allGroup.name}
        </AssignGroupMenuItem>
      )}
      {filteredAdditionalGroupHashMap.defaultGroup && (
        <AssignGroupMenuItem
          data-testid="group-filtering-default-group"
          onClick={makeHandleSelect(allAdditionalGroupHashMap.defaultGroup)}
          isSelected={computeGroupSelected(allAdditionalGroupHashMap.defaultGroup.id)}
        >
          {allAdditionalGroupHashMap.defaultGroup.name}
        </AssignGroupMenuItem>
      )}
      {filteredAdditionalGroupHashMap.fromOtherGroup && (
        <AssignGroupMenuItem
          data-testid="group-filtering-from-other-group"
          onClick={makeHandleSelect(allAdditionalGroupHashMap.fromOtherGroup)}
          isSelected={computeGroupSelected(allAdditionalGroupHashMap.fromOtherGroup.id)}
        >
          {allAdditionalGroupHashMap.fromOtherGroup.name}
        </AssignGroupMenuItem>
      )}
      {!isNoAdditional && !isNoFilteredGroups && <Divider my="6px" />}
    </Flex>
  );
}

const ComputerGroupMenuContext = createContext<{
  inputValue: string;
  setInputValue: (value: string) => void;
  onSelect: (selectedGroup: RippleComputerGroupDropdownData) => void;
  filteredGroups: Array<RippleComputerGroupDropdownData>;
  allAdditionalGroupHashMap: Required<AdditionalGroupHashMap>;
  filteredAdditionalGroupHashMap: AdditionalGroupHashMap;
  isNoAdditional: boolean;
  isNoFilteredGroups: boolean;
  computeGroupSelected: (groupId: RippleComputerGroupDropdownData['id']) => boolean;
}>({} as never);

function AssignGroupMenuItem({ children, isSelected, ...otherProps }: AssignGroupMenuItemProps): React.JSX.Element {
  return (
    <RippleButton
      variant="neutralGhost"
      display="flex"
      justifyContent="space-between"
      w="100%"
      h="min-content"
      minH="32px"
      pl="12px"
      pr="4px"
      py="6px"
      borderRadius="0px"
      outline="none"
      _focus={{ outline: 'none' }}
      {...otherProps}
    >
      <RippleBodyText02 whiteSpace="normal" wordBreak="break-all">
        {children}
      </RippleBodyText02>
      {isSelected && (
        <Box ml="8px" flexShrink={0}>
          <RippleOK color="blue.100" />
        </Box>
      )}
    </RippleButton>
  );
}
