import { Flex, forwardRef, useMultiStyleConfig } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';

import {
  RippleArrowDown16,
  RippleArrowUp16,
  RippleButton,
  RippleHeading08,
  RipplePopover,
  RipplePopoverContent,
  RipplePopoverTrigger,
  RippleStrong,
} from '@/design';
import { RippleButtonProps } from '@/design';
import { filterGroupAtom } from '@/modules/AntivirusSessions/atoms';
import { ComputerGroupMenu } from '@/modules/AntivirusSessions/components/ComputerGroupMenu';
import { useGroupListWithPermission } from '@/modules/AntivirusSessions/hooks/useGroupListWithPermission';
import { useShowDefaultGroup } from '@/modules/AntivirusSessions/hooks/useShowDefaultGroup';
import { FilterGroupValue } from '@/modules/AntivirusSessions/types';

// TODO: These should be refactored into a sharable Ripple version in storybook
export function FilterGroup(): React.JSX.Element {
  const { t } = useTranslation();
  const [filterGroup, setFilterGroup] = useAtom(filterGroupAtom);
  const groupList = useGroupListWithPermission();
  const showDefaultGroup = useShowDefaultGroup();

  const selectedGroup = groupList.find((group) => group.id === filterGroup) || { id: filterGroup, name: '' };

  return (
    <Flex flexDirection="column">
      <RippleHeading08 mb="8px">{t('computer:filterDrawer.group.label')}</RippleHeading08>
      <RipplePopover placement="bottom-start" matchWidth>
        {({ isOpen, onClose }) => (
          <>
            <RipplePopoverTrigger>
              <SelectButton isOpen={isOpen}>{selectedGroup.name}</SelectButton>
            </RipplePopoverTrigger>
            <RipplePopoverContent w="100%" boxShadow="8px">
              <ComputerGroupMenu
                showAllGroup
                showDefaultGroup={showDefaultGroup}
                showFromOtherGroup={false}
                groups={groupList}
                onSelect={(selectedGroup) => {
                  setFilterGroup(selectedGroup.id as FilterGroupValue);
                  onClose();
                }}
                selectMode
                selectedGroup={selectedGroup}
              />
            </RipplePopoverContent>
          </>
        )}
      </RipplePopover>
    </Flex>
  );
}

type SelectButtonProps = RippleButtonProps & { isOpen: boolean };
/**
 * Copied & modified from RippleSelectButton
 */
export const SelectButton = forwardRef<SelectButtonProps, 'button'>(({ isOpen, children, ...otherProps }, ref): React.JSX.Element => {
  const styles = useMultiStyleConfig('rippleSelect');

  return (
    <RippleButton ref={ref} isActive={isOpen} __css={styles.button} {...otherProps}>
      <Flex justifyContent="space-between" alignItems="center">
        <RippleStrong as="div" variant="strong02" color="inherit" isTruncated>
          {children}
        </RippleStrong>
        {isOpen ? <RippleArrowUp16 color="inherit" /> : <RippleArrowDown16 color="inherit" />}
      </Flex>
    </RippleButton>
  );
});
