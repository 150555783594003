import { useQuery } from '@tanstack/react-query';

import { featureControl } from '@/feature/toggle';
import { infoQueryService } from '@/services/users';

/** Check the accessibility for the antivirus threat page */
export type AntivirusThreatAccess = {
  canAccess: boolean;
};
/** Check the accessibility for endpoint security and antivirus */
export const useAntivirusThreatAccessQuery = () => {
  return useQuery({
    queryKey: ['info-query', 'antivirus-threat'],
    queryFn: () =>
      infoQueryService.execute({
        team_member_permissions: ['antivirus_threats'],
      }),
    enabled: featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product'),
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 5 * 60 * 1000, // 5 minutes
    select: (data): AntivirusThreatAccess => {
      const canAccess = Object.values(data).some(({ team_member_permissions }) => {
        return team_member_permissions.antivirus_threats;
      });
      return {
        canAccess,
      };
    },
  });
};
