import { useQuery } from '@tanstack/react-query';

import { featureControl } from '@/feature/toggle';
import { useAttendedTeamInformation, useUnattendedTeamInformation } from '@/models/TeamInformation';
import { infoQueryService } from '@/services/users';

import {
  shouldShowServiceDeskChannel,
  shouldShowServiceDeskChannelByEnterprise_deprecated,
  shouldShowServiceDeskChannelBySOS_deprecated,
  shouldShowServiceDeskSupportSession,
  shouldShowServiceDeskSupportSessionByEnterprise_deprecated,
  shouldShowServiceDeskSupportSessionBySOS_deprecated,
} from './utils';

/**
 * @deprecated use {@link useChannelAccessQuery} instead
 * TODO: Remove this function along with feature toggle: PCP_2760_modify_permission_checking_for_new_unified_product
 */
export function useAccessChannel_deprecated() {
  const attendedTeamInformation = useAttendedTeamInformation();
  const unattendedTeamInformation = useUnattendedTeamInformation();
  const canAccessChannelByEnterprise = shouldShowServiceDeskChannelByEnterprise_deprecated(unattendedTeamInformation);
  const canAccessChannelBySOS = shouldShowServiceDeskChannelBySOS_deprecated(attendedTeamInformation);
  const canAccessChannel = canAccessChannelByEnterprise || canAccessChannelBySOS;

  return { canAccessChannel, canAccessChannelByEnterprise, canAccessChannelBySOS };
}

/** Check whether the user can access service desk channels */
export const useChannelAccessQuery = () => {
  return useQuery({
    queryKey: ['info-query', 'channel-access'],
    queryFn: async () => {
      return await infoQueryService.execute({
        team_member_info: ['role', 'member_status', 'group_scope'],
        team_permissions: ['sos_service_desk'],
        team_member_permissions: ['attended_access', 'technician_manager'],
      });
    },
    enabled: featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product'),
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 5 * 60 * 1000, // 5 minutes
    select: (data) =>
      Boolean(
        Object.entries(data).find(([_teamKind, { team_member_info, team_permissions, team_member_permissions }]) => {
          return shouldShowServiceDeskChannel({
            role: team_member_info.role,
            groupScope: team_member_info.group_scope,
            memberStatus: team_member_info.member_status,
            sosServiceDesk: team_permissions.sos_service_desk,
            attendedAccess: team_member_permissions.attended_access,
            technicianManager: team_member_permissions.technician_manager,
          });
        }),
      ),
  });
};

/**
 * @deprecated use {@link useSupportSessionAccessQuery} instead
 * TODO: Remove this function along with feature toggle: PCP_2760_modify_permission_checking_for_new_unified_product
 */
export function useAccessSupportSession_deprecated() {
  const attendedTeamInformation = useAttendedTeamInformation();
  const unattendedTeamInformation = useUnattendedTeamInformation();
  const canAccessSupportSessionByEnterprise = shouldShowServiceDeskSupportSessionByEnterprise_deprecated(unattendedTeamInformation);
  const canAccessSupportSessionBySOS = shouldShowServiceDeskSupportSessionBySOS_deprecated(attendedTeamInformation);
  const canAccessSupportSession = canAccessSupportSessionByEnterprise || canAccessSupportSessionBySOS;

  return { canAccessSupportSession, canAccessSupportSessionByEnterprise, canAccessSupportSessionBySOS };
}

/** Check whether the user can access service desk support session */
export const useSupportSessionAccessQuery = () => {
  return useQuery({
    queryKey: ['info-query', 'support-session-access'],
    queryFn: async () => {
      return await infoQueryService.execute({
        team_member_info: ['member_status'],
        team_permissions: ['sos_service_desk'],
        team_member_permissions: ['attended_access'],
      });
    },
    enabled: featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product'),
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 5 * 60 * 1000, // 5 minutes
    select: (data) =>
      Boolean(
        Object.values(data).find(({ team_member_info, team_permissions, team_member_permissions }) =>
          shouldShowServiceDeskSupportSession({
            memberStatus: team_member_info.member_status,
            sosServiceDesk: team_permissions.sos_service_desk,
            attendedAccess: team_member_permissions.attended_access,
          }),
        ),
      ),
  });
};
