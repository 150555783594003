import { useQuery } from '@tanstack/react-query';

import { featureControl } from '@/feature/toggle';
import { useTeamInformation } from '@/models/TeamInformation';
import { infoQueryService } from '@/services/users';

export const useInventoryAccess = () => {
  const { teamKind } = useTeamInformation();

  const infoQuery = useQuery({
    queryKey: ['group_ManageAssociates', 'info'],
    queryFn: () => infoQueryService.execute({ team_member_permissions: ['inventory'] }),
    enabled: featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product'),
  });

  const isTeamAccessible = Boolean(infoQuery.data?.[teamKind]?.team_member_permissions.inventory);

  return {
    isFetched: infoQuery.isFetched,
    canAccess: isTeamAccessible,
  };
};
