import { featureControl } from '@/feature/toggle';
import { TeamKind } from '@/models/TeamInformation';
import { TeamInformation } from '@/utils/useTeamInformation';

export function shouldShowCurrentSessions(teamInformation: TeamInformation | null, sosTeamInformation: TeamInformation | null) {
  const stbCanShow = teamInformation && !teamInformation.isExpired && teamInformation.memberStatus === 'enabled';
  const sosCanShow = sosTeamInformation && !sosTeamInformation.isExpired && sosTeamInformation.memberStatus === 'enabled';

  return stbCanShow || sosCanShow;
}

/**
 * @deprecated use src/hooks/accessibility/useSosCustomizationAccessQuery.ts instead
 * TODO: Remove this function along with feature toggle PCP_2760_modify_permission_checking_for_new_unified_product
 */
export function shouldShowSOSCustomizationBySOS_deprecated(sosTeamInformation: TeamInformation | null) {
  if (!sosTeamInformation) return false;
  if (sosTeamInformation.plan === 'legacy') return false;
  return (
    sosTeamInformation.role === 'owner' ||
    (sosTeamInformation.role === 'manager' && sosTeamInformation.teamMemberPermissions.technicianManager)
  );
}

/**
 * @deprecated use src/hooks/accessibility/useSosCustomizationAccessQuery.ts instead
 * TODO: Remove this function along with feature toggle PCP_2760_modify_permission_checking_for_new_unified_product
 */
export function shouldShowSOSCustomizationByEnterprise_deprecated(teamInformation: TeamInformation | null) {
  if (!teamInformation) return false;
  return (
    teamInformation.currentTeam === 'srs' &&
    [
      'enterprise',
      'ste_lite_sos_basic',
      'ste_lite_sos_plus',
      ...(featureControl.getToggle('PCP_2061__TeamSettings__SOS_Unlimited_team_computer_quota_hardcode_adjustment')
        ? ['ste_lite_sos_unlimited']
        : []),
    ].includes(teamInformation.plan) &&
    teamInformation.role !== 'member' &&
    teamInformation.teamMemberPermissions.attendedAccess
  );
}

/**
 * @deprecated use src/hooks/accessibility/useSosCustomizationAccessQuery.ts instead
 * TODO: Remove this function along with feature toggle PCP_2760_modify_permission_checking_for_new_unified_product
 */
export function shouldShowSOSCustomization_deprecated(teamInformation: TeamInformation | null, sosTeamInformation: TeamInformation | null) {
  return (
    shouldShowSOSCustomizationBySOS_deprecated(sosTeamInformation) || shouldShowSOSCustomizationByEnterprise_deprecated(teamInformation)
  );
}

export function shouldShowVulnerabilityScore(teamInformation: TeamInformation | null) {
  return teamInformation?.role !== 'member' && teamInformation?.seatPermissions?.vulnerabilityScore;
}

export function shouldShowInventory(teamInformation: TeamInformation | null) {
  if (!teamInformation) return false;
  return teamInformation.teamPermissions.premierPack && teamInformation.role !== 'member';
}

/**
 * @deprecated use `shouldShowPreferencePolicy_new` instead
 */
export function shouldShowPreferencePolicy(teamInformation: TeamInformation | null) {
  if (!teamInformation) return false;

  if (featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product')) {
    return (
      ['sba', 'srs', 'msp', 'splashtop', 'ste_custom'].includes(teamInformation.currentTeam) &&
      (teamInformation?.role === 'manager' || teamInformation?.role === 'owner')
    );
  }

  return (
    ['sba', 'srs', 'msp'].includes(teamInformation.currentTeam) &&
    (teamInformation?.role === 'manager' || teamInformation?.role === 'owner')
  );
}

/**
 * @param teamKind Value from `useTeamInformation` (src/models/TeamInformation)
 * @param isManager {boolean} Value from `useTeamRole`
 */
export function shouldShowPreferencePolicy_new(teamKind: TeamKind, isManager: boolean) {
  if (featureControl.getToggle('PCP_2760_modify_permission_checking_for_new_unified_product')) {
    return ['sba', 'srs', 'msp', 'splashtop', 'ste_custom'].includes(teamKind) && isManager;
  }

  return ['sba', 'srs', 'msp'].includes(teamKind) && isManager;
}

/** @deprecated use info-query instead */
export function shouldShowAntivirusPolicy(teamInformation: TeamInformation | null, hasBitDefender2?: boolean) {
  if (!teamInformation || !hasBitDefender2) return false;
  return (
    ['sba', 'srs', 'msp'].includes(teamInformation.currentTeam) &&
    (teamInformation?.role === 'manager' || teamInformation?.role === 'owner')
  );
}
